import React from 'react'
import UserProfileCss from './UserProfile.module.css'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { axiosApi } from '../../defz';
import { useState } from 'react';

export default function UserProfile() {
    const [Get, setGet] = useState([])
    axiosApi(
        "api/user/get/profile",
        "",
        localStorage.getItem("admin-token")
    )
        .then((res) => {
            console.log(res);
            setGet(res.data.data.user)
        })
    return (
        <div className={UserProfileCss.container}>
            <Link to='/MemberProfilePage'>
                <p className={UserProfileCss.Name}>{Get}</p>
                <FontAwesomeIcon icon={faUser} className={UserProfileCss.NameIcon} />
            </Link>
        </div>
    )
}
