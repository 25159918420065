import React from 'react'
import PagesCSS from "../Styles/pages.module.css"
import Activitiesinfo from '../../components/Activities-Info/Activitie-info'
import Sidebar from "../../components/Sidebar/Sidebar-menu";

export default function ActivitiesPage() {
  return (
    <div className={PagesCSS.container}>
      <Sidebar page={"reports"} />
      <main className={PagesCSS.main}>
      <Activitiesinfo />
      </main>
    </div>
  )
}
