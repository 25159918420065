import React, { useEffect, useState } from "react";
import EditMemberCss from "./MemberProfileEdit.module.css";
import { Link } from "react-router-dom";
import { axiosApi } from "../../defz";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import { FaUser } from "react-icons/fa";
import { FaBuilding } from "react-icons/fa";
import { AiFillLock } from "react-icons/ai";
import { MdEmail } from "react-icons/md";

export default function MemberProfileEdit() {
  const navigate = useNavigate();
  const [user, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [organization, setOrganization] = useState("");
  const [Password, setPassword] = useState("");
  const [Loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axiosApi("api/user/get/profile", "", localStorage.getItem("admin-token"))
      // .then(res => console.log(res))

      .then((res) => {
        if (res.data.status_code === 200) {
          setUser(res.data.data.user);
          setEmail(res.data.data.email);
          setOrganization(res.data.data.organizartion);
          setPassword(res.data.password);
          setLoading(false);
        } else {
          toast.error("Error");
          setLoading(false);
        }
      });
  }, []);

  const postData = {
    password: Password,
    user: user,
    email: email,
    organizartion: organization,
  };

  const submit = (event) => [
    event.preventDefault(),
    axiosApi(
      "api/user/edit/profile",
      postData,
      localStorage.getItem("admin-token")
    ).then((res) => {
      // console.log(res)
      if (res.data.status_code === 200) {
        toast.success("Done");
        navigate("/PslistPagePage");
      } else {
        toast.error("Error");
      }
    }),
  ];
  return (
    <div className={EditMemberCss.container}>
      {Loading && Loader}
      <div className={EditMemberCss.main}>
        <div className={EditMemberCss.mainChild}>
          <form className={EditMemberCss.profileForm}>
            <label className={EditMemberCss.item}>
              User{" "}
              <div className={EditMemberCss.inputBox}>
                <FaUser className={EditMemberCss.Icon} />
                <span className={EditMemberCss.seperator}></span>
                <input
                  type="text"
                  defaultValue={user}
                  onChange={(e) => setUser(e.target.value)}
                  placeholder="username"
                />
              </div>
            </label>
            {/* <div className={EditMemberCss.inputBox}>
                            <FaUser className={EditMemberCss.userIcon}/><span className={EditMemberCss.seperator}></span><input type='text' defaultValue={user} onChange={e => setUser(e.target.value)} />
                        </div> */}
            <label className={EditMemberCss.item}>
              Password
              <div className={EditMemberCss.inputBox}>
                <AiFillLock className={EditMemberCss.Icon} />
                <span className={EditMemberCss.seperator}></span>
                <input
                  type="text"
                  defaultValue={Password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password..."
                />
              </div>
            </label>
            <label className={EditMemberCss.item}>
              Email
              <div className={EditMemberCss.inputBox}>
                <MdEmail className={EditMemberCss.Icon} />
                <span className={EditMemberCss.seperator}></span>
                <input
                  type="text"
                  defaultValue={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email..."
                />
              </div>
            </label>
            <label className={EditMemberCss.item}>
              Organization
              <div className={EditMemberCss.inputBox}>
                <FaBuilding className={EditMemberCss.Icon} />
                <span className={EditMemberCss.seperator}></span>
                <input
                  type="text"
                  defaultValue={organization}
                  onChange={(e) => setOrganization(e.target.value)}
                  placeholder="Organization..."
                />
              </div>
            </label>
            <div className={EditMemberCss.footer}>
              <article className={EditMemberCss.rulesBox}>
                <h6>Password requirments</h6>
                <p>Please follow this guide for a strong password</p>
                <ul className={EditMemberCss.rulesList}>
                  <li>One special character</li>
                  <li>Min 4 characters</li>
                  <li>One number (2 are recommended)</li>
                  <li>Change it often</li>
                </ul>
              </article>
              <div lassName={EditMemberCss.footer_btn}>
                <button
                  style={{ background: "#43bb63", marginBottom: "61px" }}
                  onClick={submit}
                >
                  Submit
                </button>
                <button
                  style={{ background: "red" }}
                  onClick={() => navigate("/MemberProfilePage")}
                >
                  Back
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
