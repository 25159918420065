import React, { useState } from "react";
import { axiosApi } from "../../defz";
import LoginformCSS from "./Login.module.css";
import TextField from "@mui/material/TextField";
import { toast } from "react-hot-toast";
import { FaUserCircle } from "react-icons/fa";

export default function EnterEmail() {
  const [email, setEmail] = useState("");

  const getMail = (e) => {
    e.preventDefault();

    if (!email) {
      toast.error("Email is required.", { duration: 1000 });
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      toast.error("Email address is invalid", { duration: 1000 });
    } else {
      toast.loading("Please wait to Login...", { duration: 1000 });
      axiosApi("api/user/forget/password", { email }, "").then((res) =>
        console.log(res)
      );
    }
  };

  return (
    <div className={LoginformCSS.container}>
      <form>
        <FaUserCircle className={LoginformCSS.icon} />
        <h4 style={{ marginBottom: "12px" }}>
          Enter your Email to reset password:{" "}
        </h4>
        <TextField
          className={LoginformCSS.inputs}
          onChange={(e) => setEmail(e.target.value)}
          id="outlined-basic"
          label="email"
          variant="outlined"
        />
        <button 
          className={LoginformCSS.submitBtn}
          type="submit"
          onClick={getMail}
        >
          Continue
        </button>
      </form>
    </div>
  );
}
