import React, { useEffect, useState } from "react";
import ItemCSS from "./Item.module.css";
import { axiosApi } from "../../defz";
import toast from "react-hot-toast";
import Loader from "../../components/Loader/Loader";
import { AiFillFlag, AiFillPlusCircle } from "react-icons/ai";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { Link, useParams } from "react-router-dom";
import { Pagination } from "@mui/material";

export default function ItemIssue() {
  const { id } = useParams();

  const [load, setLoad] = useState(false);
  const [psData, setPsData] = useState();

  const getPsInfo = () => {
    setLoad(true);
    axiosApi(
      "api/user/get/ps_one",
      { psid: id },
      localStorage.getItem("admin-token")
    )
      .then((res) => {
        setLoad(false);
        setPsData(res.data.data[0]);
      })
      .catch((res) => toast.error("Fail to connect to server"));
  };

  useEffect(() => {
    getPsInfo();
    // eslint-disable-next-line
  }, []);

  const handleIssueStatus = (id, status) => {
    setLoad(true);
    axiosApi(
      "api/up/del",
      {
        col: "iteam-issue",
        action: "update",
        id: id,
        status: status === "Resolve" ? "Resolved" : "Resolve",
      },
      localStorage.getItem("admin-token")
    ).then((res) => {
      setLoad(false);
      getPsInfo();
    });
  };

  const handlePermission = (e) => {
    if (localStorage.getItem("type") === "0" || "1") {
      return 0;
    } else {
      e.preventDefault();
    }
  };

  return (
    <div
      className={ItemCSS.container}
      style={{ width: "100%", marginTop: "0" }}
    >
      {load && <Loader />}
      <h1 className={ItemCSS.title}>Items Tasks</h1>
      <div className={ItemCSS.items}>
        <section className={ItemCSS.main} style={{ width: "100%" }}>
          <div className={ItemCSS.TasksContainer}>
            <div className={ItemCSS.addBtn}>
              <AiFillFlag size="30" fill="#252525" />
              <div className={ItemCSS.addBTNSTYLE}>
                <AiFillPlusCircle style={{ verticalAlign: "middle" }} />
                <Link to={`/add-item-issue/${id}`} onClick={handlePermission}>
                  Add New Issue
                </Link>
              </div>
            </div>
            <div className={ItemCSS.Tasks}>
              {psData?.issue.map((item, index) => {
                return (
                  <div key={index} className={ItemCSS.issuesItems}>
                    <p
                      style={{
                        backgroundColor:
                          item.status === "Resolved" ? "#61C454" : "",
                      }}
                    >
                      {item.text}
                    </p>
                    <button
                      onClick={
                        item.status === "Resolved"
                          ? null
                          : () => handleIssueStatus(item._id, item.status)
                      }
                      className={
                        item.status === "Resolve"
                          ? `${ItemCSS.issueStatusBtn} ${ItemCSS.issueStatusBtnResolve}`
                          : ItemCSS.issueStatusBtn
                      }
                      disabled={
                        localStorage.getItem("type") === "0" || "1"
                          ? false
                          : true
                      }
                    >
                      {item.status}
                    </button>
                    <p>{item.version}</p>
                  </div>
                );
              })}
              <footer className={ItemCSS.Pagination}>
                <Pagination count={1} color="primary" />
              </footer>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
