import React from 'react'
import PagesCSS from "../Styles/pages.module.css"
import TestCreate from '../../components/Test-Create/Test-Create'
import Sidebar from '../../components/Sidebar/Sidebar-menu'

export default function CreateTestPage() {
  return (
    <div className={PagesCSS.container}>
        <Sidebar page="pslist" />
      <main className={PagesCSS.addMain} style={{width:"80%"}}>
        <TestCreate />
      </main>
    </div>
  )
}
