import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import LoginformCSS from "./Login.module.css";
import { axiosApi } from "../../defz";
import TextField from '@mui/material/TextField';
import { FaUserCircle } from 'react-icons/fa';
import { BsEyeSlashFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";


export default function Loginform() {
  const navigate = useNavigate();

  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [eyeChange, seteyeChange] = useState(false)
  const LoginData = {
    user: username,
    pas: password,
  };

  const Login = (e) => {
    e.preventDefault();
    toast.loading("Please wait to Login...", { duration: 1000 });
    axiosApi("api/admin/login", LoginData).then((res) => {
      console.log(res, 'login log')
      if (res.data.status_code === 200) {
        localStorage.setItem("admin-token", res.data.admin_token);
        localStorage.setItem("id", res.data.id);
        localStorage.setItem("type", res.data.type);
        localStorage.setItem("DashboardThemeColor", 'blue');
        navigate("/PslistPage");
        window.location.reload()
      } else {
        toast.error(res.data.description);
        setUserName("")
        setPassword("")
      }
    });
  };


  const eyeHandler = () => {
    if (eyeChange === true) {
      seteyeChange(false)
    }
    else {
      seteyeChange(true)
    }
  }

  return (
    <div className={LoginformCSS.container}>
      <form onSubmit={Login}>
        <FaUserCircle className={LoginformCSS.icon} />
        <h3>Welcome</h3>
        <div className={LoginformCSS.divistion}>
          <TextField className={LoginformCSS.inputs} value={username} type="text" onChange={(e) => setUserName(e.target.value)} id="outlined-basic" label="Username / Email" variant="outlined" />
          <MdEmail className={LoginformCSS.emailIcon} />
        </div>
        <div className={LoginformCSS.divistion}>
          <TextField
            className={LoginformCSS.inputs}
            type={eyeChange === true ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            id="outlined-basic"
            label="Password"
            variant="outlined"
          />
          <BsEyeSlashFill className={LoginformCSS.eyeIcon} onClick={eyeHandler} />
        </div>

        <button type="submit" className={LoginformCSS.submitBtn}>
          Log In
        </button>
        <Link id="link" to="/send-email" style={{ fontSize: ".9rem", color: "#283bc7", }}>
          Forgot Password?
        </Link>
      
      </form>
    </div>
  )
}
