import React, { useEffect, useState } from "react";
import tasksCss from "./ShowTasks.module.css";
import { AiFillCaretDown } from "react-icons/ai";
import { axiosApi } from "../../defz";
import { toast } from "react-hot-toast";
import { Pagination } from "@mui/material";
import Loader from "../Loader/Loader";

export default function ShowTasks() {
  const [showTaskData, setShowTaskData] = useState([]);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(0);
  const [loading, setloading] = useState(false);
  const handleChange = (e, p) => {
    setPage(p);
  };

  const fetch = () => {
    setloading(true);
    axiosApi(
      "api/user/get/task",
      { number: 1 },
      localStorage.getItem("admin-token")
    )
      .then((res) => {
        console.log(res);
        if (res.data.status_code === 200) {
          setMaxPage(res.data.max_page);
          setShowTaskData(res.data.info);
          setloading(false);
          console.log(res);
        } else {
          setloading(false);
          toast.error("Error");
        }
      })
      .catch((res) => {
        toast.error("Fail to connect to server");
        setloading(false);
      });
  };

  useEffect(() => {
    fetch();
  }, [page]);

  const updateBTN = (id, status) => {
    setloading(true);
    axiosApi(
      "api/up/del",
      {
        col: "tasks",
        action: "update",
        id: id,
        status: status === "pending" ? "Done" : "pending",
      },
      localStorage.getItem("admin-token")
    )
      .then((res) => {
        if (res.data.status_code === 200) {
          fetch();
          toast.success("Done");
          setloading(false);
        } else {
          toast.error("Eror");
          setloading(false);
        }
      })
      .catch((res) => {
        toast.error("Fail to connect to server");
        setloading(false);
      });
  };
  return (
    <div className={tasksCss.container}>
      {loading && <Loader />}
      <h1>Tasks</h1>
      <div className={tasksCss.main}>
        <div className={tasksCss.tableDV}>
          <table className={tasksCss.tasks}>
            <tbody>
              <tr className={tasksCss.Info}>
                <th>
                  <div className={tasksCss.tableHeadContainer}>
                    no
                    <AiFillCaretDown />
                  </div>
                </th>
                <th>Tasks</th>
                <th>State</th>
                <th>Deadline</th>
                <th>Complete</th>
              </tr>
            </tbody>
            {showTaskData.map((item, index) => {
              return (
                <tbody key={index}>
                  <tr>
                    <td>{showTaskData.length - index}</td>
                    <td>{item.task}</td>
                    <td>{item.state}</td>
                    <td>{item.deadline}</td>
                    <td>
                      <button
                      style={{width:"50%"}}
                        className={
                          item.status === "pending"
                            ? `${tasksCss.mainBTN} ${tasksCss.mainResolveBTN}`
                            : tasksCss.mainBTN
                        }
                        disabled={
                          localStorage.getItem("type") === "0" || "1"
                            ? false
                            : true
                        }
                        onClick={
                          item.status === "Done"
                            ? null
                            : () => updateBTN(item._id, item.status)
                        }
                      >
                        {item.status}
                      </button>
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </table>
        </div>
        <div className={tasksCss.PaginationShowTask}>
          <Pagination
            count={maxPage}
            color="primary"
            onChange={handleChange}
          ></Pagination>
        </div>
      </div>
    </div>
  );
}
