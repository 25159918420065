import React from 'react'
import ActivitiesCSS from "./Access-DeniedPage.module.css"
import Accessdenied from '../../components/Access-Denied/Access-denied';
import Sidebar from "../../components/Sidebar/Sidebar-menu";

export default function AccessDeniedPage() {
  return (
    <div className={ActivitiesCSS.containerI}>
      <Sidebar />
      <main className={ActivitiesCSS.container}>
      <Accessdenied />
      </main>
    </div>
  )
}
