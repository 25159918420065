import React from 'react'
import PagesCSS from "../Styles/pages.module.css"
import Sidebar from '../../components/Sidebar/Sidebar-menu'
import { AddStage } from '../../components/schAddStage/AddStage'
import SvrReport from '../../components/ReportsStar/SvrReports'

export default function SvrPage() {
  return (
    <div className={PagesCSS.container}>
        <Sidebar page="pslist" />
        <main className={PagesCSS.main}>
            <SvrReport />
        </main>
    </div>
  )
}
