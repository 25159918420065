import React from "react";
import ItemTest from "../../components/Items/ItemTest";
import PagesCSS from "../Styles/pages.module.css"
import Sidebar from "../../components/Sidebar/Sidebar-menu";

export default function ItemsTestPage() {
  return (
    <div className={PagesCSS.container}>
      <Sidebar page="items" />
      <main className={PagesCSS.main}>
        <ItemTest />
      </main>
    </div>
  );
}
