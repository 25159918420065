import React from "react";
import SidebarCSS from './Sidebar.module.css'
import {ReactComponent as Home} from "../../assets/pics/home.svg"
import { ReactComponent as Calender } from "../../assets/pics/calender.svg"
import {ReactComponent as Test} from "../../assets/pics/test.svg"
import {ReactComponent as Tasks} from "../../assets/pics/tasks.svg"
import {ReactComponent as Reports} from "../../assets/pics/reports.svg"
import { ReactComponent as Activity } from "../../assets/pics/activity.svg"
import { ReactComponent as Member } from "../../assets/pics/member.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faChartSimple, faHouse, faListCheck, faUserGroup, faCog, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import {faCalendar} from "@fortawesome/free-regular-svg-icons"

export const SidebarData = [
    {
        title: "Dashboard",
        path: "/PslistPage",
        icon: <FontAwesomeIcon icon={faHouse} className={SidebarCSS.icon} color="#fff" />
    },
    {
        title: "Schedule",
        path: "/SchPage",
        // icon: <FontAwesomeIcon icon={faCalendar} className={SidebarCSS.icon} color="#367E18" />
        icon: <FontAwesomeIcon icon={faCalendar} className={SidebarCSS.icon} color="#fff" />
    },
    {
        title: "Tests",
        path: "/tests",
        icon: <FontAwesomeIcon icon={faCheckCircle} className={SidebarCSS.icon} color="#fff" />
    },
    {
        title: "Tasks",
        path: "/ShowTaskPage",
        // icon: <FontAwesomeIcon icon={faListCheck} className={SidebarCSS.icon} color="#2F58CD" />
        icon: <FontAwesomeIcon icon={faListCheck} className={SidebarCSS.icon} color="#fff" />
    },
    {
        title: "Reports",
        path: "/LinegraphPsPage",
        // icon: <FontAwesomeIcon icon={faChartSimple} className={SidebarCSS.icon} color="#ff0" />
        icon: <FontAwesomeIcon icon={faChartSimple} className={SidebarCSS.icon} color="#fff" />
    },
    {
        title: "Activity",
        path: "/activity",
        // icon: <FontAwesomeIcon icon={faCog} className={SidebarCSS.icon} color="#77037B" />
        icon: <FontAwesomeIcon icon={faCog} className={SidebarCSS.icon} color="#fff" />
    },    
    {
        title: "Members",
        path: `${ localStorage.getItem("type") === "0" ? "/members" : "/access-denied" }`,
        icon: <FontAwesomeIcon icon={faUserGroup} className={SidebarCSS.icon} color="#fff" />
    }
]