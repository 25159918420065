import React from "react";
import SnakeCss from "./Addstage.module.css";

function SnakeHammer({ data, direction }) {
  function getMonthName(Number) {
    const month = Number.split("-")[1];
    const day = Number.split("-")[2];
    const year = Number.split("-")[0];
    console.log(day);
    const date = new Date();
    date.setMonth(month - 1);
    const monthName = date.toLocaleString("en-US", {
      month: "long",
    });

    const fulllYear = `${monthName} ${day} ${year}`;
    return fulllYear;
  }
  return (
    <div
      className={
        direction
          ? `${SnakeCss.snakeHammer} ${SnakeCss.snakeHammerLtr}`
          : `${SnakeCss.snakeHammer} ${SnakeCss.snakeHammerRtl}`
      }
    >
      <div className={SnakeCss.snakeLong}>
        {data.length !== 0
          ? data.slice(0, 10).map((item, index) => {
              return (
                <div className={SnakeCss.sheduleData} key={index}>
                  <p>{getMonthName(item.date)}</p>
                  <div className={SnakeCss.dataCount}>{item.count_time}</div>
                  <p>{item.version}</p>
                </div>
              );
            })
          : ""}
      </div>
      <div className={SnakeCss.snakeShort}>
        {data.slice(10).map((item, index) => {
          return (
            <div className={SnakeCss.sheduleData} key={index}>
              <div className={SnakeCss.dataCount}>{item.count_time}</div>
              <p>
                <p>{getMonthName(item.date)}</p>
                {item.version}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SnakeHammer;
