import React from "react";
import PagesCSS from "../Styles/pages.module.css"
import Sidebar from "../../components/Sidebar/Sidebar-menu";
import AddTask from "../../components/itemsAdd/Add-Task";

export default function AddTaskPage() {
  return (
    <div className={PagesCSS.container}>
      <Sidebar page="pslist" />
      <main className={PagesCSS.main}>
        <AddTask />
      </main>
    </div>
  );
}
