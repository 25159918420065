import React from 'react'
import PagesCSS from "../Styles/pages.module.css"
import Sidebar from '../../components/Sidebar/Sidebar-menu'
import PsList from '../../components/PsList/PsList'

export default function PslistPage() {
  return (
    <div className={PagesCSS.container}>
      <Sidebar page="pslist" />
      <main className={PagesCSS.main}>
        <PsList />
      </main>
    </div>
  )
}
