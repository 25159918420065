import React, { useEffect, useState } from "react";
import PsListCSS from "../PsList/PsList.module.css";
import { Link, useNavigate } from "react-router-dom";
import { AiFillPlusCircle } from "react-icons/ai";
import { AiOutlineUsergroupDelete } from "react-icons/ai";
import { AiTwotoneFlag } from "react-icons/ai";
import { axiosApi } from "../../defz";
import { Pagination } from "@mui/material";
import Loader from "../Loader/Loader";
import { AiOutlineClose } from "react-icons/ai";
import { AiOutlineSearch } from "react-icons/ai";

function PsList() {
  const color = [
    "#e57373",
    "#ba68c8",
    "#90caf9",
    "#4db6ac",
    "#dce775",
    "#ffb74d",
    "#b0bec5",
    "#81c784",
  ];
  const [psData, setPsData] = useState([]);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(0);
  const [Loading, setLoading] = useState(false);
  const [ListOfUs, setListOfUs] = useState(false);
  const [themeColor, setThemeColor] = useState("blue");
  const [lightColor, setLightColor] = useState("#97DEFF");
  const [searchStatus, setSearchStatus] = useState(false);
  const [searchField, setSearchField] = useState("");

  let navigate = useNavigate();

  const routeChange = (d) => {
    navigate(`/items/${d}`);
  };

  useEffect(() => {
    let themeColorVar = localStorage.getItem("DashboardThemeColor");
    setThemeColor(themeColorVar);
  });
  useEffect(() => {
    switch (themeColor) {
      case "blue":
        setLightColor("#97DEFF");
        break;
      case "green":
        setLightColor("#B5F1CC");
        break;
      case "purple":
        setLightColor("#E5D1FA");
        break;
      case "red":
        setLightColor("#f8cdde");
        break;
      case "gold":
        setLightColor("#FFD93D");
        break;
      default:
        break;
    }
  }, [themeColor]);
  useEffect(() => {
    setLoading(true);
    axiosApi(
      "api/user/get/ps",
      { number: page },
      localStorage.getItem("admin-token")
    ).then((res) => {
      setLoading(false);
      console.log(res);
      ListOfUs === false ? setPsData(res.data.data) : setPsData(res.data.myps);
      setMaxPage(res.data.max_page);
    });
  }, [page, ListOfUs]);

  const handleSearch = (e) => {
    e.preventDefault()
    setLoading(true);
    axiosApi(
      "api/user/search/ps",
      { search: searchField },
      localStorage.getItem("admin-token")
    ).then((res) => {
      console.log(res);
      setLoading(false);
      if (res.data.status_code === 200) {
        setPsData(res.data.data);
      }
    });
    setSearchStatus(!searchStatus);
  };

  const handleChange = (e, p) => {
    setPage(p);
  };

  return (
    <div className={PsListCSS.container}>
      {Loading && <Loader />}
      <div className={PsListCSS.main}>
        <div className={PsListCSS.header}>
          <form className={PsListCSS.searchBar} onSubmit={handleSearch}>
            <input
              type="text"
              value={searchField}
              onChange={(e) => setSearchField(e.target.value)}
              placeholder="Search"
            />
            <button onClick={handleSearch}>
              {searchStatus ? (
                <AiOutlineClose size="24" />
              ) : (
                <AiOutlineSearch size="30" />
              )}
            </button>
          </form>
          <h1>Ps</h1>
          <div className={PsListCSS.items}>
            <button
              className={
                ListOfUs === true
                  ? `${PsListCSS.headerLink} ${PsListCSS.headerLinkA}`
                  : PsListCSS.headerLink
              }
              onClick={(e) => setListOfUs(true)}
            >
              List Of Us
            </button>
            <button
              className={
                ListOfUs === false
                  ? `${PsListCSS.headerLink} ${PsListCSS.headerLinkA}`
                  : PsListCSS.headerLink
              }
              onClick={(e) => setListOfUs(false)}
            >
              List Of Items
            </button>
          </div>
          <div className={PsListCSS.addBtn}>
            <Link to="/createPsPage">
              <AiFillPlusCircle />
              Add New Item
            </Link>
          </div>
          {/* <div className={PsListCSS.colorBox}>
            <IoMdColorPalette />
            <div className={PsListCSS.palette}>
              <div className={PsListCSS.colorItem} style={{ background: 'red' }} onClick={() => themeColorHandler('R')}> </div>
              <div className={PsListCSS.colorItem} style={{ background: 'purple' }} onClick={() => themeColorHandler('P')}> </div>
              <div className={PsListCSS.colorItem} style={{ background: 'green' }} onClick={() => themeColorHandler('Gr')}> </div>
              <div className={PsListCSS.colorItem} style={{ background: 'blue' }} onClick={() => themeColorHandler('B')}> </div>
              <div className={PsListCSS.colorItem} style={{ background: 'orange' }} onClick={() => themeColorHandler('Go')}> </div>
            </div>
          </div> */}
        </div>
        <div className={PsListCSS.mainChild}>
          {psData.map((item, index) => {
            console.log(item);
            let randomColorIndex = Math.floor(Math.random() * color.length);
            return (
              <div
                key={index}
                className={PsListCSS.ps}
                onClick={() => routeChange(item._id)}
              >
                <div className={PsListCSS.headerPS}>
                  <em
                    style={{
                      backgroundColor: `${color[randomColorIndex]}`,
                      boxShadow: `3px 2px 5px ${color[randomColorIndex]}, -2px 2px 5px ${color[randomColorIndex]}`,
                    }}
                    className={PsListCSS.PsProfile}
                  >
                    Ps
                  </em>
                  <p style={{ color: "#4D5E80" }} className={PsListCSS.title}>
                    {item.name}
                  </p>
                </div>
                <div className={PsListCSS.Psmain}>
                  <div className={PsListCSS.row}>
                    <p>Version</p>
                    <p>{item.test_version}</p>
                  </div>
                  <div className={PsListCSS.row}>
                    <p>U</p>
                    <input type="checkbox" checked={item.U && true} disabled />
                    <p>{item.state}</p>
                  </div>
                  <div className={PsListCSS.footer}>
                    <p>
                      <AiTwotoneFlag
                        style={{ color: color[randomColorIndex] }}
                      />
                      6
                    </p>
                    <p>
                      <AiOutlineUsergroupDelete
                        style={{ color: color[randomColorIndex] }}
                      />
                      10
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className={PsListCSS.PaginationPs}>
          <Pagination count={maxPage} color="primary" onChange={handleChange}>
            {" "}
          </Pagination>
        </div>
      </div>
      <div></div>
    </div>
  );
}

export default PsList;
