import React, { useEffect } from "react";
import addStage from "./Addstage.module.css";
import { useState } from "react";
import { axiosApi } from "../../defz";
import Loader from "../Loader/Loader";
import { toast } from "react-hot-toast";
import SnakeHammer from "./SnakeHammer";
import SnakeRowComp from "./SnakeRowComp";

export const AddStage = (props) => {
  const [psData, setPsData] = useState([]);
  const [Date, setDate] = useState("");
  const [Version, setVersion] = useState("");
  const [Loading, setLoading] = useState(false);
  const [Get, setGet] = useState([]);
  const [dataForRow, setDataForRow] = useState([]);
  const [id, setid] = useState("");
  const [numberOfHammers, setNumberOfHammers] = useState(0);
  const [rowDataNumber, setRowDataNumber] = useState(0);
  const [arr, setArr] = useState([]);
  // const [Version, setVersion] = useState([])

  const schForm = {
    date: Date,
    version: Version,
    psid: id,
  };
  useEffect(() => {
    let divide = 0;
    let rowData = 0;
    setLoading(true);
    axiosApi(
      "api/user/get/sch",
      {
        number: "all",
      },
      localStorage.getItem("admin-token")
    )
      .then((res) => {
        console.log(res);
        let dataMain = res.data.info.reverse()
        divide = dataMain.length / 12;
        rowData = Math.ceil((divide % 1) * 12);
        setNumberOfHammers(Math.floor(divide));
        setRowDataNumber(rowData);
        console.log(rowData);
        // setGet(res.data.info.slice(0, Math.floor(divide) * 12));
        setDataForRow(dataMain.slice(res.data.info.length - rowData));
        setLoading(false);
        setGet(dataMain);
        // when data is 11
        if (rowData === 11) {
          setNumberOfHammers(1);
          setRowDataNumber(0);
          setGet(dataMain);
          setDataForRow([]);
          arr.push(1);
        }
        for (let i = 0; i < Math.floor(divide); i++) {
          arr.push(i);
        }
      })
      .catch((res) => {
        toast.error("Fail to connect to server");
        setLoading(false);
      });
    axiosApi(
      "api/user/get/ps",
      { number: "all" },
      localStorage.getItem("admin-token")
    ).then((res) => {
      setPsData(res.data.data);
    });
  }, []);

  // useEffect(() => {
  //   console.log(dataForRow, Get);
  //   console.log(arr);
  // }, [dataForRow, Get, arr]);

  const submit = (e) => {
    e.preventDefault();
    axiosApi(
      "api/user/add/sch",
      schForm,
      localStorage.getItem("admin-token")
    ).then((res) => {
      window.location.reload();
    });
  };

  return (
    <div className={addStage.container}>
      {Loading && <Loader />}
      <h1>Sch</h1>
      <div className={addStage.main}>
        <div className={addStage.mainA}>
          <form>
            <label>
              Date :{" "}
              <input
                type="date"
                onChange={(e) => {
                  setDate(e.target.value);
                }}
              />
            </label>
            <label>
              Version :
              <select
                onChange={(e) => setVersion(e.target.value)}
                defaultValue="choose"
              >
                <option hidden>No version</option>
                {psData.map((item, index) => {
                  return (
                    <option key={index} value={item.test_version}>
                      {item.test_version}
                    </option>
                  );
                })}
              </select>
            </label>
            <label>
              Ps :
              <select
                onChange={(e) => setid(e.target.value)}
                defaultValue="choose"
              >
                <option hidden disabled>
                  choose
                </option>
                {psData.map((item, index) => {
                  return (
                    <option key={index} value={item._id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </label>
            <button onClick={submit}>submit</button>
          </form>
        </div>
        <div className={addStage.mainB}>
          {arr.map((item, index) => {
            return (
              <SnakeHammer
              key={index}
                data={Get.slice(index * 12, (index + 1) * 12)}
                direction={!(index % 2) ? true : false}
              />
            );
          })}
          
          {rowDataNumber !== 0 ? (
            <SnakeRowComp
              data={dataForRow}
              direction={!(numberOfHammers % 2) ? true : false}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};
