import React, { useEffect, useState } from "react";
import MemberCss from "./MemberProfile.module.css";
import { Link } from "react-router-dom";
import { axiosApi } from "../../defz";
import Loader from "../Loader/Loader";
import { toast } from "react-hot-toast";
import { FiEdit3 } from "react-icons/fi";
import { FaUser } from "react-icons/fa";
import { FaBuilding } from "react-icons/fa";
import { AiFillLock } from "react-icons/ai";
import { MdEmail } from "react-icons/md";

export default function MemberProfile() {
  const [profileData, setProfileData] = useState([]);
  const [Loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axiosApi("api/user/get/profile", "", localStorage.getItem("admin-token"))
      .then((res) => {
        console.log(res);
        setProfileData(res.data.data);
        setLoading(false);
      })
      .catch((res) => {
        toast.error("server Eror");
        setLoading(false);
      });
  }, []);
 

  return (
    <div className={MemberCss.container}>
      {Loading && <Loader />}
      {/* <h1>Profile</h1> */}
      <div className={MemberCss.main}>
        <div className={MemberCss.mainChild}>
          <div
            className={MemberCss.head}
          >
            <div className={MemberCss.user_detail}>
              <img src="/images/user.png" alt="profile" />
              <div className={MemberCss.text}>
                <h4>{profileData.user}</h4>
                <h6>admin</h6>
              </div>
            </div>
          </div>
          <form className={MemberCss.profileForm}>
            <label className={MemberCss.item}>
              <span className={MemberCss.title}>User </span>
              <div className={MemberCss.inputBox}>
                <FaUser />
                <span className={MemberCss.seperator}></span>
                <input
                  type="text"
                  value={`${profileData.user}`}
                  disabled="disabled"
                />
              </div>
            </label>
            <label className={MemberCss.item}>
              <span className={MemberCss.title}>Email </span>
              <div className={MemberCss.inputBox}>
                <MdEmail />
                <span className={MemberCss.seperator}></span>
                <input
                  type="text"
                  value={`${profileData.email}`}
                  disabled="disabled"
                />
              </div>
            </label>
            <label className={MemberCss.item}>
              <span className={MemberCss.title}>Organization </span>
              <div className={MemberCss.inputBox}>
                <FaBuilding />
                <span className={MemberCss.seperator}></span>
                <input
                  type="text"
                  value={`${profileData.organizartion}`}
                  disabled="disabled"
                />
              </div>
            </label>
            <label className={MemberCss.item}>
              <span className={MemberCss.title}>Password </span>
              <div className={MemberCss.inputBox}>
                <AiFillLock />
                <span className={MemberCss.seperator}></span>
                <input
                  type="password"
                  value={profileData.password}
                  disabled="disabled"
                />
              </div>
            </label>
          </form>
          <Link
            to="/MemberProfileEditPage"
            className={MemberCss.Edit}
          >
            <span> Edit </span> <FiEdit3 />
          </Link>
        </div>
      </div>
    </div>
  );
}
