import React, { useEffect, useState } from "react";
import ItemCSS from "./Item.module.css";
import { axiosApi } from "../../defz";
import toast from "react-hot-toast";
import Loader from "../../components/Loader/Loader";
import { AiFillPlusCircle, AiOutlineOrderedList } from "react-icons/ai";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { Link, useParams } from "react-router-dom";

export default function ItemTask() {
  const { id } = useParams();

  const [load, setLoad] = useState(false);
  const [psData, setPsData] = useState();
  const [page, setPage] = useState(1);

  const getPsInfo = () => {
    setLoad(true);
    axiosApi(
      "api/user/get/ps_one",
      { psid: id },
      localStorage.getItem("admin-token")
    )
      .then((res) => {
        setLoad(false);
        setPsData(res.data.data[0]);
      })
      .catch((res) => toast.error("Fail to connect to server"));
  };

  useEffect(() => {
    getPsInfo();
    // eslint-disable-next-line
  }, []);

  const handleTaskStatus = (id, status) => {
    setLoad(true);
    axiosApi(
      "api/up/del",
      {
        col: "tasks",
        action: "update",
        id: id,
        status: status === "pending" ? "Done" : "pending",
      },
      localStorage.getItem("admin-token")
    ).then((res) => {
      setLoad(false);
      getPsInfo();
    });
  };

  const handlePermission = (e) => {
    if (localStorage.getItem("type") === "0" || "1") {
      return 0;
    } else {
      e.preventDefault();
    }
  };
  return (
    <div className={ItemCSS.container} style={{ width: "100%" }}>
      {load && <Loader />}
      <h1 className={ItemCSS.title}>Items Tasks</h1>
      <div className={ItemCSS.items}>
        {/* <aside>
          <div className={ItemCSS.ver}>
            <p>Version:</p>
            <p>{psData?.test_version}</p>
          </div>
          <div className={ItemCSS.U}>
            <p>U:</p>
            <input type="checkbox" />
            <p>1.2.3.4</p>
            <button>Complete</button>
          </div>
          <div className={ItemCSS.cal}>
            <p>Oout:</p>
            <p>{psData?.date_made}</p>
          </div>
          <div className={ItemCSS.noSer}>
            <p>Number of services:</p>
            <p>12</p>
          </div>
          <div className={ItemCSS.ser}>
            {psData?.srv.map((item, index) => {
              return (
                <button key={index} className={ItemCSS.serBtn}>
                  {item}
                </button>
              );
            })}
          </div>
          <div className={ItemCSS.autoR}>
            <h4>Automatic report</h4>
            <div>
              <button >Download API</button>
              <button >Deactivate</button>
            </div>
          </div>
          <div className={ItemCSS.manR}>
            <h4>Manual Report</h4>
            <div>
              <button>Down</button>
              <button>Up</button>
            </div>
            <button className={ItemCSS.maintenanceBtn}>Maintenance</button>
          </div>
        </aside> */}
        <section className={ItemCSS.main} style={{ width: "100%" }}>
          <div className={ItemCSS.TasksContainer}>
            <div className={ItemCSS.addBtn}>
              <AiOutlineOrderedList size="30" fill="#252525" />
              <div className={ItemCSS.addbtnIcon}>
                <AiFillPlusCircle style={{ verticalAlign: "middle" }} />
                <Link to={`/add-item-task/${id}`} onClick={handlePermission}>
                  Add New Task
                </Link>
              </div>
            </div>
            <div className={ItemCSS.Tasks}>
              {psData?.task.map((item, index) => {
                return (
                  <div key={index} className={ItemCSS.tasksItemsM}>
                    <p>{index + 1}</p>
                    <p>{item.task}</p>
                    <p>Deadline: {item.deadline}</p>
                    <button
                      className={
                        item.status === "pending"
                          ? `${ItemCSS.taskstatusBtnM} ${ItemCSS.taskstatusBtnDoneM}`
                          : ItemCSS.taskstatusBtnM
                      }
                      onClick={
                        item.status === "Done"
                          ? null
                          : () => handleTaskStatus(item._id, item.status)
                      }
                      disabled={
                        localStorage.getItem("type") === "0" || "1"
                          ? false
                          : true
                      }
                    >
                      {item.status}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
