import React from 'react'
import Sidebar from '../../components/Sidebar/Sidebar-menu'
import PagesCSS from "../Styles/pages.module.css"
import VersionReports from '../../components/ReportsStar/versionReports'

export default function VersionReportsPage() {
    return (
        <div className={PagesCSS.container}>
            <Sidebar />
            <main className={PagesCSS.main}>
                <VersionReports />
            </main>
        </div>
    )
}
