import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { axiosApi } from "../../defz";
import PsInfoCSS from "./Ps-Info.module.css";

export default function PsInfo() {
  const GET_PS_INFO = "api/user/get/ps_one";

  const { id: psid } = useParams();

  const [data, setData] = useState({});
  const [times, setTimes] = useState({
    up: 0,
    down: 0,
    maintenance: 0,
  });

  const calculateDifference = (timeData) => {
    let sumHour = 0;
    for (let i = 0; i < timeData.length; i++) {
      let date1 = new Date(timeData[i]?.start);
      let date2 = !!timeData[i]?.end ? new Date(timeData[i]?.end) : new Date();
      let diff = (date2.getTime() - date1.getTime()) / 1000;
      diff /= 60 * 60;
      sumHour += Math.abs(Math.round(diff));
    }
    return sumHour;
  };

  useEffect(() => {
    axiosApi(GET_PS_INFO, { psid }, localStorage.getItem("admin-token")).then(
      (res) => {
        setData(res.data.data[0]);
        let upDifference = calculateDifference(res.data.data[0]?.work?.up_time);
        let downDifference = calculateDifference(
          res.data.data[0]?.work?.down_time
        );
        let maintenanceDifference = calculateDifference(
          res.data.data[0]?.work?.maintenace_time
        );
        setTimes({
          up: upDifference,
          down: downDifference,
          maintenance: maintenanceDifference,
        });
      }
    );
  }, []);

  return (
    <div className={PsInfoCSS.container}>
      <div className={PsInfoCSS.titles}>
        <p>Created Date</p>
        <p>Cs</p>
        <p>Cs Email</p>
        <p>Up(hours)</p>
        <p>Maintenance</p>
        <p>Down</p>
        <p>Capacity</p>
        <p>Loc</p>
        <p>TS</p>
        <p>Model</p>
        <p>In</p>
        <p>Bt</p>
      </div>
      <div className={PsInfoCSS.infos}>
        <p>{data.date_made === "" ? "dd-mm-yyyy" : data.date_made}</p>
        <p>{data?.cs}</p>
        <p>skajdfhsd</p>
        <p>{times.up}</p>
        <p>{times.maintenance}</p>
        <p>{times.down}</p>
        <p>10</p>
        <p>a2</p>
        <p>UTL bla</p>
        <p>{data?.mdl}</p>
        <p>{data?.in}</p>
        <p>{data?.bt_md}</p>
      </div>
    </div>
  );
}
