import React from 'react'
import Sidebar from '../../components/Sidebar/Sidebar-menu'
import LineGraphPs from '../../components/ReportsStar/LineGraphPs'
import PagesCSS from "../Styles/pages.module.css"

export default function LinegraphPsPage() {
    return (
        <div className={PagesCSS.container}>
            <Sidebar page={"reports"} />
            <main className={PagesCSS.main}>
                <LineGraphPs />
            </main>
        </div>
    )
}
