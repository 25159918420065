import React, { useEffect, useState } from "react";
import { axiosApi } from "../../defz";
import ItemCSS from "./Item.module.css";
import Calendar from "../Calendar/Calendar";
import { AiFillCaretDown } from "react-icons/ai";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";

export default function ItemRemoteMsg() {
  const { id } = useParams();

  const [psData, setPsData] = useState();

  const getPsInfo = () => {
    axiosApi(
      "api/user/get/ps_one",
      { psid: id },
      localStorage.getItem("admin-token")
    )
      .then((res) => {
        setPsData(res.data.data[0]);
      })
      .catch((res) => toast.error("Fail to connect to server"));
  };

  useEffect(() => {
    getPsInfo();
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className={ItemCSS.container}
      style={{ width: "100%", marginTop: "0" }}
    >
      <h1 className={ItemCSS.title}>Items</h1>
      <div className={ItemCSS.items}>
        <aside>
          <div className={ItemCSS.ver}>
            <p>Version:</p>
            <p>1.2.3.4</p>
          </div>
          <div className={ItemCSS.U}>
            <p>U:</p>
            <input type="checkbox" />
            <p>1.2.3.4</p>
            <button>Complete</button>
          </div>
          <div className={ItemCSS.cal}>
            <p>Oout:</p>
            <Calendar />
          </div>
          <div className={ItemCSS.noSer}>
            <p>Number of services:</p>
            <p>12</p>
          </div>
          <div className={ItemCSS.ser}>
            {psData?.srv.map((item, index) => {
              return (
                <button key={index} className={ItemCSS.serBtn}>
                  {item.title}
                </button>
              );
            })}
          </div>
          <div className={ItemCSS.autoR}>
            <h4>Automatic report</h4>
            <div>
              <button>Download API</button>
              <button>Deactivate</button>
            </div>
          </div>
          <div className={ItemCSS.manR}>
            <h4>Manual Report</h4>
            <div>
              <button>Down</button>
              <button>Up</button>
            </div>
            <button className={ItemCSS.maintenanceBtn}>Maintenance</button>
          </div>
        </aside>
        <div className={ItemCSS.container}>
          {/* <div>{ load && <Loader /> }</div> */}
          <div className={ItemCSS.items}>
            <table>
              <tr>
                <th>
                  <div>
                    No
                    <AiFillCaretDown color="gray" />
                  </div>
                </th>
                <th>
                  <div>
                    Date/Time
                    <AiFillCaretDown color="gray" />
                  </div>
                </th>
                <th>
                  <div>
                    Message
                    <AiFillCaretDown color="gray" />
                  </div>
                </th>
              </tr>
              <tbody>
                <tr className={ItemCSS.Rrow}>
                  <td>
                    <div>x</div>
                  </td>
                  <td>
                    <div>y</div>
                  </td>
                  <td>
                    <div>z</div>
                  </td>
                </tr>
                <tr className={ItemCSS.Rrow}>
                  <td>
                    <div>x</div>
                  </td>
                  <td>
                    <div>y</div>
                  </td>
                  <td>
                    <div>z</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
