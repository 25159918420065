import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import Layout from "./components/Layout/Layout";

const root = createRoot(document.getElementById("root"));

root.render(
//   <StrictMode>
    <BrowserRouter>
      <Layout>
        <App />
      </Layout>
    </BrowserRouter>
//   </StrictMode>
);
