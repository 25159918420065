import React from 'react'
import ChangePass from '../../components/Login/Change-Pass'

export default function ChangePassPage() {
  return (
    <div>
      <ChangePass />
    </div>
  )
}
