import React, { useState } from "react";
import AddTaskCSS from "./ItemsAdd.module.css";
import { axiosApi } from "../../defz";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

export default function AddTask() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [task, setTask] = useState("");
  const [deadline, setDeadline] = useState("");

  const AddTaskData = {
    psid: id,
    task: task,
    deadline: deadline,
    state: "Ongoing",
  };

  const submit = (e) => {
      e.preventDefault();
    if (deadline === 0) {
      toast.error("You must fill all the fields");
      return 0;
    }
    if (task.length < 2) {
      toast.error("You must fill all the fields");
      return 0;
    }
    axiosApi(
      "api/user/add/tasks",
      AddTaskData,
      localStorage.getItem("admin-token")
    ).then((res) => {
      if (res.data.status_code === 200) {
        toast.success(res.data.description);
        setTimeout(() => {
          navigate("/ShowTaskPage");
        }, 2000);
      } else {
        toast.error(res.data.description);
      }
    });
  };
  return (
    <div className={AddTaskCSS.container}>
      <h1>Add Tasks</h1>
      <div className={AddTaskCSS.main}>
        <form action="#">
          <div className={AddTaskCSS.items}>
            <label className={AddTaskCSS.item}>Task:</label>
            <input
              type="text"
              className={AddTaskCSS.inp}
              onChange={(e) => setTask(e.target.value)}
            />
          </div>
          <div className={AddTaskCSS.items}>
            <label className={AddTaskCSS.item}>Deadline:</label>
            <input
              type="date"
              className={AddTaskCSS.inp}
              onChange={(e) => setDeadline(e.target.value)}
            />
          </div>
          <input className={AddTaskCSS.submit} type="submit" onClick={submit} />
        </form>
      </div>
    </div>
  );
}
