import React from "react";
import MembersAdd from "../../components/Members-Add/Members-Add";
import PagesCSS from "../Styles/pages.module.css";
import Sidebar from "../../components/Sidebar/Sidebar-menu";

export default function MembersAddPage() {
  return (
    <div className={PagesCSS.container}>
      <Sidebar page="members" />
      <main className={PagesCSS.addMain} style={{ width: "100%" }}>
        <MembersAdd />
      </main>
    </div>
  );
}
