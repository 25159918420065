import React from "react";
import Sidebar from "../../components/Sidebar/Sidebar-menu";
import ShowTasks from "../../components/showTasks/ShowTasks";
import PagesCSS from "../Styles/pages.module.css"

export default function ShowTaskPage() {
  return (
    <div className={PagesCSS.container}>
      <Sidebar page="pslist" />
      <main className={PagesCSS.main}>
        <ShowTasks />
      </main>
    </div>
  );
}
