import React from 'react'
import Loginform from '../../components/Login/Login-form';

export default function LoginPage() {
  return (
    <div >
      <Loginform />
    </div>
  )
}
