import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import StatusCss from "./StatusReports.module.css";
import { Pie } from "react-chartjs-2";
import { axiosApi } from "../../defz";
import { NavLink } from "react-router-dom";
import { toast } from "react-hot-toast";
import Loader from "../Loader/Loader";
import { FaFileDownload } from "react-icons/fa";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function StatusReports() {
  // const [hex, setHex] = useState('#ffffff')
  const [StatusData, setStatusData] = useState({});
  const [percentages, setPersentages] = useState([]);
  const [colorArr, setColorArr] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [link, setLink] = useState("");
  let state = {
    labels: StatusData?.data,
    datasets: [
      {
        label: "rainfall",
        backgroundColor: colorArr,
        data: percentages,
      },
    ],
  };
  const exportHandeling = () => {
    axiosApi(
      "api/excel",
      {
        type: "status",
      },
      localStorage.getItem("admin-token")
    ).then((res) => {
      toast.success("success");
      // console.log(res);
      setLink(res.data.link);
    });
  };

  useEffect(() => {
    let sum = 0;
    let arr = [];
    let colorArr1 = [];
    setLoading(true);
    axiosApi(
      "api/report/finder/status",
      "",
      localStorage.getItem("admin-token")
    )
      .then((res) => {
        for (let i = 0; i < res.data.count.length; i++) {
          sum += res.data.count[i];
        }
        res.data.count.map((item) => {
          return arr.push((item / sum) * 100);
        });
        setPersentages(arr);
        setStatusData(res.data);

        for (let y = 0; y < res.data.data.length; y++) {
          let randomColor =
            "#" + Math.floor(Math.random() * 16777215).toString(16);
          // setHex(randomColor)
          colorArr1.push(randomColor);
        }
        setColorArr(colorArr1);
        setLoading(false);
      })
      .catch((res) => {
        toast.error("Fail to connect to server");
        setLoading(false);
      });
  }, []);

  return (
    <div className={StatusCss.container}>
      {Loading && <Loader />}
      <h1>Reports</h1>
      <div className={StatusCss.main}>
        <div className={StatusCss.stats}>
          <span className={StatusCss.item}>
            <NavLink to="/LinegraphPsPage">Stat 1</NavLink>
          </span>
          <span className={StatusCss.item}>
            <NavLink to="/ReportCountriesPage">Stat 2</NavLink>
          </span>
          <span className={StatusCss.item}>
            <NavLink to="/ReportsStatusPage">Stat 3</NavLink>
          </span>
          <span className={StatusCss.item}>
            <NavLink to="/versionReports">Stat 4</NavLink>
          </span>
          <span className={StatusCss.item}>
            <NavLink to="/PwrReports">Stat 5</NavLink>
          </span>
          <span className={StatusCss.item}>
            <NavLink to="/svrReports">Stat 6</NavLink>
          </span>
        </div>
        <div className={StatusCss.chartMain}>
          <div className={StatusCss.chartHead}>
            <h2>Status</h2>
            <NavLink
              to={link}
              className={StatusCss.exportIcon}
              onClick={exportHandeling}
            >
              Export
              <FaFileDownload size="20px" />
            </NavLink>
          </div>
          <div className={StatusCss.test1}>
            <Pie
              data={state}
              options={{
                title: {
                  display: true,
                  Text: "wwwww",
                  fontSize: 20,
                },
                legend: {
                  display: true,
                  position: "right",
                },
              }}
              width={700}
              height={700}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
