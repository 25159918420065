import React from 'react'
import PagesCSS from "../Styles/pages.module.css"
import Sidebar from '../../components/Sidebar/Sidebar-menu'
import ItemChats from '../../components/Items/ItemChats'

export default function ItemsChatsPage() {
  return (
    <div className={PagesCSS.container}>
      <Sidebar page="items" />
      <main className={PagesCSS.main}>
        <ItemChats />
      </main>
    </div>
  )
}

