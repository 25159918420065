import React, { useState, useEffect } from "react";
import MembersAddCSS from "./Members-Add.module.css";
import { axiosApi } from "../../defz";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Loader from "../../components/Loader/Loader";
import { FaUserPlus } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { FaBuilding } from "react-icons/fa";

export default function MembersAdd() {
  const navigate = useNavigate();

  const [load, setLoad] = useState(false);
  const [type, setType] = useState(0);
  const [name, setName] = useState("");
  const [org, setOrg] = useState("");
  const [email, setEmail] = useState("");
  const [themeColor, setThemeColor] = useState("#362FD9");

  useEffect(() => {
    let themeColorTemp = localStorage.getItem("DashboardThemeColor");
    switch (themeColorTemp) {
      case "blue":
        setThemeColor("#362FD9");
        break;
      case "green":
        setThemeColor("#5D9C59");
        break;
      case "red":
        setThemeColor("#DF2E38");
        break;
      case "purple":
        setThemeColor("#77037B");
        break;
      case "gold":
        setThemeColor("#FF8400");
        break;
      default:
        break;
    }
  });

  const memberFormData = {
    user: name,
    type,
    org,
    email,
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const submitCreate = (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      toast.error("Please enter valid mail");
      return 0;
    }
    if (org.length === 0) {
      toast.error("You must fill all the fields");
      return 0;
    }
    if (name.length < 3) {
      toast.error("Name must have 3 characters at least");
      return 0;
    }
    setLoad(true);
    axiosApi(
      "api/admin/create/user",
      memberFormData,
      localStorage.getItem("admin-token")
    ).then((res) => {
      if (res.data.status_code === 200) {
        setLoad(false);
        toast.success(res.data.description);
        setTimeout(() => {
          navigate("/members");
        }, 3000);
      } else {
        // toast.error(res.data.description);
        console.log(res);
      }
    });
  };

  return (
    <div className={MembersAddCSS.container}>
      {load && <Loader />}
      <h1>Add Member Page</h1>
      <div className={MembersAddCSS.items}>
        <div className={MembersAddCSS.head} style={{ background: themeColor }}>
          <FaUserPlus /> <h5>New member info</h5>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill={themeColor}
            fill-opacity="1"
            d="M0,32L30,48C60,64,120,96,180,122.7C240,149,300,171,360,160C420,149,480,107,540,106.7C600,107,660,149,720,154.7C780,160,840,128,900,144C960,160,1020,224,1080,218.7C1140,213,1200,139,1260,96C1320,53,1380,43,1410,37.3L1440,32L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0C120,0,60,0,30,0L0,0Z"
          ></path>
        </svg>
        <form className={MembersAddCSS.addMemberForm} action="#">
          <div className={MembersAddCSS.form_item}>
            <label>Name</label>
            <div className={MembersAddCSS.inputBox}>
              <FaUser />
              <span className={MembersAddCSS.seperator}></span>
              <input
                className={MembersAddCSS.inp}
                onChange={(e) => setName(e.target.value)}
                placeholder="username ..."
              />
            </div>
          </div>
          <div className={MembersAddCSS.form_item}>
            <label>Organization</label>
            <div className={MembersAddCSS.inputBox}>
              <FaBuilding />
              <span className={MembersAddCSS.seperator}></span>
              <input
                className={MembersAddCSS.inp}
                onChange={(e) => setOrg(e.target.value)}
                placeholder="Organization ..."
              />
            </div>
          </div>
          <div className={MembersAddCSS.form_item}>
            <label>Email</label>
            <div className={MembersAddCSS.inputBox}>
              <MdEmail />
              <span className={MembersAddCSS.seperator}></span>
              <input
                type="email"
                className={MembersAddCSS.inp}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="email ..."
              />
            </div>
          </div>
          <div className={MembersAddCSS.last_form_item}>
            <label>Type</label>
            <select onChange={(e) => setType(e.target.value)}>
              <option>Admin</option>
              <option>1</option>
              <option>2</option>
              <option>3</option>
            </select>
          </div>
          <input
            className={MembersAddCSS.submit}
            type="submit"
            onClick={submitCreate}
            style={{ background: themeColor }}
          />
        </form>
      </div>
    </div>
  );
}
