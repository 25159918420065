import React, { useEffect, useState } from "react";
import ItemCSS from "./Item.module.css";
import { axiosApi } from "../../defz";
import toast from "react-hot-toast";
import Loader from "../../components/Loader/Loader";
import { AiFillCaretDown, AiFillFlag, AiFillPlusCircle } from "react-icons/ai";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { Link, useParams } from "react-router-dom";
import { Pagination } from "@mui/material";

export default function ItemTest() {
  const { id } = useParams();

  const [load, setLoad] = useState(false);
  const [psData, setPsData] = useState();
  console.log(psData);

  const getPsInfo = () => {
    setLoad(true);
    axiosApi(
      "api/user/get/ps_one",
      { psid: id },
      localStorage.getItem("admin-token")
    )
      .then((res) => {
        console.log(res.data.data[0].test);
        setLoad(false);
        setPsData(res.data.data[0].test);
      })
      .catch((res) => toast.error("Fail to connect to server"));
  };

  useEffect(() => {
    getPsInfo();
    // eslint-disable-next-line
  }, []);

  const handleIssueStatus = (id, status) => {
    setLoad(true);
    axiosApi(
      "api/up/del",
      {
        col: "iteam-issue",
        action: "update",
        id: id,
        status: status === "Resolve" ? "Resolved" : "Resolve",
      },
      localStorage.getItem("admin-token")
    ).then((res) => {
      setLoad(false);
      getPsInfo();
    });
  };

  const handlePermission = (e) => {
    if (localStorage.getItem("type") === "0" || "1") {
      return 0;
    } else {
      e.preventDefault();
    }
  };

  return (
    <div
      className={ItemCSS.container}
      style={{ width: "100%", marginTop: "0" }}
    >
      {load && <Loader />}
      <h1 className={ItemCSS.title}>Items Test</h1>
      <div className={ItemCSS.items}>
        <section className={ItemCSS.main} style={{ width: "100%" }}>
          <div className={ItemCSS.TasksContainer}>
            {/* <div className={ItemCSS.addBtn}>
              <AiFillFlag size="30" fill="#252525" />
              <div className={ItemCSS.addBTNSTYLE}>
                <AiFillPlusCircle style={{ verticalAlign: "middle" }} />
                <Link to={`/add-item-issue/${id}`} onClick={handlePermission}>
                  Add New test
                </Link>
              </div>
            </div> */}
            <div className={ItemCSS.Tasks}>
              {/* {psData.map((item, index) => {
                return (
                  <div key={index} className={ItemCSS.issuesItems}>
                    <p>{`${index + 1}`}</p>
                    <p
                      style={{
                        backgroundColor:
                          item.status === "Resolved" ? "#61C454" : "",
                      }}
                    >
                      {item.text}
                    </p>
                    <button
                      onClick={
                        item.status === "Resolved"
                          ? null
                          : () => handleIssueStatus(item._id, item.status)
                      }
                      className={
                        item.status === "Resolve"
                          ? `${ItemCSS.issueStatusBtn} ${ItemCSS.issueStatusBtnResolve}`
                          : ItemCSS.issueStatusBtn
                      }
                      disabled={
                        localStorage.getItem("type") === "0" || "1"
                          ? false
                          : true
                      }
                    >
                      {item.status}
                    </button>
                    <p>{item.version}</p>
                  </div>
                );
              })} */}
              <table>
                <thead>
                  <tr className={ItemCSS.tableHead}>
                    <th className={ItemCSS.mainTitleRow}>
                      <div>
                        No
                        <AiFillCaretDown color="gray" />
                      </div>
                    </th>
                    <th className={ItemCSS.mainTitleRow}>
                      <div>
                        Version
                        <AiFillCaretDown color="gray" />
                      </div>
                    </th>
                    <th className={ItemCSS.mainTitleRow}>
                      <div>
                        Created
                        <AiFillCaretDown color="gray" />
                      </div>
                    </th>
                    <th className={ItemCSS.mainTitleRow}>
                      <div>
                        State
                        <AiFillCaretDown color="gray" />
                      </div>
                    </th>
                    <th className={ItemCSS.mainTitleRow}>
                      <div>
                        Ps
                        <AiFillCaretDown color="gray" />
                      </div>
                    </th>
                    <th className={ItemCSS.mainTitleRow}>
                      <div>
                        RN
                        <AiFillCaretDown color="gray" />
                      </div>
                    </th>
                    <th className={ItemCSS.mainTitleRow}>
                      <div>
                        RN2
                        <AiFillCaretDown color="gray" />
                      </div>
                    </th>
                    <th className={ItemCSS.mainTitleRow}>
                      <div>
                        Type
                        <AiFillCaretDown color="gray" />
                      </div>
                    </th>
                    <th className={ItemCSS.mainTitleRow}>
                      <div>
                        Files
                        <AiFillCaretDown color="gray" />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* <tr className={ItemCSS.tableHead}>
                    <th className={ItemCSS.mainTitleRow}>
                      <div>
                        No
                        <AiFillCaretDown color="gray" />
                      </div>
                    </th>
                    <th className={ItemCSS.mainTitleRow}>
                      <div>
                        Version
                        <AiFillCaretDown color="gray" />
                      </div>
                    </th>
                    <th className={ItemCSS.mainTitleRow}>
                      <div>
                        Created
                        <AiFillCaretDown color="gray" />
                      </div>
                    </th>
                    <th className={ItemCSS.mainTitleRow}>
                      <div>
                        State
                        <AiFillCaretDown color="gray" />
                      </div>
                    </th>
                    <th className={ItemCSS.mainTitleRow}>
                      <div>
                        Ps
                        <AiFillCaretDown color="gray" />
                      </div>
                    </th>
                    <th className={ItemCSS.mainTitleRow}>
                      <div>
                        RN
                        <AiFillCaretDown color="gray" />
                      </div>
                    </th>
                    <th className={ItemCSS.mainTitleRow}>
                      <div>
                        RN2
                        <AiFillCaretDown color="gray" />
                      </div>
                    </th>
                    <th className={ItemCSS.mainTitleRow}>
                      <div>
                        Type
                        <AiFillCaretDown color="gray" />
                      </div>
                    </th>
                    <th className={ItemCSS.mainTitleRow}>
                      <div>
                        Files
                        <AiFillCaretDown color="gray" />
                      </div>
                    </th>
                    <th className={ItemCSS.mainTitleRow}>
                      <div>
                        Action
                        <AiFillCaretDown color="gray" />
                      </div>
                    </th>
                  </tr>  */}
                  {psData?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.version}</td>
                        <td>{item.time.slice(0, 16)}</td>
                        <td>{item.state}</td>
                        <td>{item.ps}</td>
                        <td>
                          <Link className={ItemCSS.linkImgD} to={item.RN1}>
                            {item.RN1.split("/").slice(-1)}
                          </Link>
                        </td>
                        <td>
                          <Link className={ItemCSS.linkImgD} to={item.RN2}>
                            {item.RN2.split("/").slice(-1)}
                          </Link>
                        </td>
                        <td>{item.type}</td>
                        <td>
                          <Link className={ItemCSS.linkImgD} to={item.file}>
                            {item.file.split("/").slice(-1)}
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <footer className={ItemCSS.Pagination}>
                <Pagination count={1} color="primary" />
              </footer>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
