import React from "react";
import "./home.css";
import Logo from "../../assets/homepageImg/logo.png";
import img1 from "../../assets/homepageImg/img-1.png";
import img2 from "../../assets/homepageImg/img-2.png";
import img3 from "../../assets/homepageImg/img-3.png";
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <div>
      <div className="header_section1">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div
            className="logo"
            style={{
              marginTop: "6px",
            }}
          >
            <img src={Logo} alt="" />
          </div>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <Link className="nav-link" to="/login">
                  DASHBOARD
                </Link>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#about">
                  ABOUT US
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#contact">
                  CONTACT US
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>

      <div className="banner_section layout_padding">
        <div className="container">
          <div id="my_slider" className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active1">
                <div className="row">
                  <div className="col-md-5">
                    <h1 className="video_text">Dashboard is:</h1>
                    <p className="banner_text">
                      A dashboard ba bla is a visual representation of data and
                      information designed to provide at a glance insights and
                      facilitate decision making. Dashboards are widely used in
                      various fields and industries, from business and finance
                      to healthcare and technology, to monitor key performance
                      indicators (KPIs) and track progress toward specific goals
                      or objectives.
                    </p>
                    <div className="shop_bt">
                      <Link to="/login">Go Dashboard</Link>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div>
                      <img src={img2} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="about_section layout_padding" id="about">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="image_2">
                <img src={img1} alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <h1 className="about_text text-left">ABOUT</h1>
              <p className="lorem_text text-left">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Egestas purus viverra accumsan in nisl nisi. Arcu cursus vitae
                congue mauris rhoncus aenean vel elit scelerisque. In egestas
                erat imperdiet sed euismod nisi porta lorem mollis. Morbi
                tristique senectus et netus. Mattis pellentesque id nibh tortor
                id aliquet lectus proin. Sapien faucibus et molestie ac feugiat
                sed lectus vestibulum. Ullamcorper velit sed ullamcorper morbi
                tincidunt ornare massa eget. Dictum varius duis at consectetur
                lorem. Nisi vitae suscipit tellus mauris a diam maecenas sed
                enim. Velit ut tortor pretium viverra suspendisse potenti
                nullam. Et molestie ac feugiat sed lectus. Non nisi est sit amet
                facilisis magna. Dignissim diam quis enim lobortis scelerisque
                fermentum. Odio ut enim blandit volutpat maecenas volutpat.
                Ornare lectus sit amet est placerat in egestas erat. Nisi vitae
                suscipit tellus mauris a diam maecenas sed. Placerat duis
                ultricies lacus sed turpis tincidunt id aliquet.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="video_section layout_padding" id="contact">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1 className="video_text_2">CONTACT US</h1>
              <p className="banner_text_2">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud
              </p>
            </div>
            <div className="col-md-6">
              <div className="image_4">
                <img src={img3} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="about_section layout_padding">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="image_2">
                <img src={img1} alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <h1 className="about_text text-left">ABOUT</h1>
              <p className="lorem_text text-left">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Egestas purus viverra accumsan in nisl nisi. Arcu cursus vitae
                congue mauris rhoncus aenean vel elit scelerisque. In egestas
                erat imperdiet sed euismod nisi porta lorem mollis. Morbi
                tristique senectus et netus. Mattis pellentesque id nibh tortor
                id aliquet lectus proin. Sapien faucibus et molestie ac feugiat
                sed lectus vestibulum. Ullamcorper velit sed ullamcorper morbi
                tincidunt ornare massa eget. Dictum varius duis at consectetur
                lorem. Nisi vitae suscipit tellus mauris a diam maecenas sed
                enim. Velit ut tortor pretium viverra suspendisse potenti
                nullam. Et molestie ac feugiat sed lectus. Non nisi est sit amet
                facilisis magna. Dignissim diam quis enim lobortis scelerisque
                fermentum. Odio ut enim blandit volutpat maecenas volutpat.
                Ornare lectus sit amet est placerat in egestas erat. Nisi vitae
                suscipit tellus mauris a diam maecenas sed. Placerat duis
                ultricies lacus sed turpis tincidunt id aliquet.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
