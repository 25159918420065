import React, { useEffect, useState } from 'react'
import StatsCSS from './Stats.module.css'
import Chart from '../Chart/Chart'
import { axiosApi } from '../../defz'


export default function SidebarChart({ page }) {

    const GET_SLIDER_DATA = "api/ps/count/slider"

    const [psCountData,setPsCountData] = useState({})

    useEffect(() => {
        axiosApi(GET_SLIDER_DATA,{}, localStorage.getItem('admin-token'))
        .then((res) => {
            console.log(res);
          setPsCountData(res.data)
        })
      }, [])

    return (
        <div className={StatsCSS.container}>
            <section className={StatsCSS.items}>
                <div className={StatsCSS.item}>
                    <button className={StatsCSS.item1}>{psCountData.count_all}</button>
                    <p>ps</p>
                </div>
                <div className={StatsCSS.item}>
                    <button className={StatsCSS.item2}>{psCountData.count_cs}</button>
                    <p>cs</p>
                </div>
                <div className={StatsCSS.item}>
                    <button className={StatsCSS.item3}>{psCountData.count_up}</button>
                    <p>Up</p>
                </div>
                <div className={StatsCSS.item}>
                    <button className={StatsCSS.item4}>{psCountData.count_down}</button>
                    <p>Down</p>
                </div>
                <div className={StatsCSS.item}>
                    <button className={StatsCSS.item5}>{psCountData.count_maintenace}</button>
                    <p>Maintenance</p>
                </div>
                <div className={StatsCSS.item}>
                    <button className={StatsCSS.item6}>{psCountData.count_DVIp}</button>
                    <p>DVIp</p>
                </div>
            </section>
            {
                page === "reports" || page === "tests"
                    ? ""
                    :
                    <>
                        <section className={StatsCSS.summary}>
                            <div className={StatsCSS.titles}>
                                <p>Total cap</p>
                                <p>Up time</p>
                                <p>Maintenance</p>
                            </div>
                            <div className={StatsCSS.info}>
                                <p>100%</p>
                                <p>98%</p>
                                <p>1%</p>
                            </div>
                        </section>
                        <Chart />
                    </>
            }
        </div>
    )
}
