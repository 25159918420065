import React from "react";
import { useLocation } from "react-router-dom";
import UserProfile from "../UserProfile/UserProfile";

export default function Layout({ children }) {
  const location = useLocation();
  return (
    <>
      {location.pathname !== "/" && location.pathname !== "/send-email" && location.pathname !== "/login" && location.pathname !== "/userlogin" ? <UserProfile /> : ""}
      {children}
    </>
  );
}
