import React from "react";
import PagesCSS from "../Styles/pages.module.css"
import Testlist from "../../components/Test-List/Test-list";
import Sidebar from "../../components/Sidebar/Sidebar-menu";

export default function TestsPage() {
  return (
    <div className={PagesCSS.container}>
      <Sidebar page="tests" />
      <main className={PagesCSS.main}>
        <Testlist />
      </main>
    </div>
  );
}
