import React from 'react'
import PagesCSS from "../Styles/pages.module.css"
import MembersList from '../../components/Members-List/Members-List'
import Sidebar from '../../components/Sidebar/Sidebar-menu'

export default function MembersPage() {
  return (
    <div className={PagesCSS.container}>
      <Sidebar page="members" />
      <main className={PagesCSS.main}>
        <MembersList />
      </main>
    </div>
  )
}
