import { React, useMemo } from "react";
import CreatePscss from "./createPS.module.css";
import { useState } from "react";
import { AiFillPlusCircle } from "react-icons/ai";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { axiosApi } from "../../defz";
import countryList from "react-select-country-list";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";

export default function CreatePs() {
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [In, setIn] = useState("");
  const [InError, setInError] = useState(false);
  const [bt_md, setBt_md] = useState("");
  const [bt_mdError, setBt_mdError] = useState(false);
  const [srvD, setSrvD] = useState("");
  const [srvDError, setSrvDError] = useState(false);
  const [srv, setSrv] = useState([]);
  const [srvError, setSrvError] = useState(false);
  const [status, setStatus] = useState("");
  const [statusError, setStatusError] = useState(false);
  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState(false);
  const [hr, setHr] = useState();
  const [hrError, setHrError] = useState(false);
  const [rn_type, setRn_type] = useState("");
  const [rn_typeError, setRn_typeError] = useState(false);
  const [cs, setCs] = useState("");
  const [csError, setCsError] = useState(false);
  const [pwr, setPwr] = useState();
  const [pwrError, setPwrError] = useState(false);
  const [date_made, setDate_made] = useState("");
  const [date_madeError, setDate_madeError] = useState(false);
  const [bt_type, setBt_type] = useState("");
  const [bt_typeError, setBt_typeError] = useState(false);
  const [tso, setTso] = useState("");
  const [tsoError, setTsoError] = useState(false);
  const [test_version, setTest_version] = useState("");
  const [test_versionError, setTest_versionError] = useState(false);
  const [mdl, setMdl] = useState("");
  const [mdlError, setMdlError] = useState(false);
  const [state, setState] = useState("");
  const [stateError, setStateError] = useState(false);
  const [str_type, setStr_type] = useState("");
  const [str_typeError, setStr_typeError] = useState(false);
  const [rn_pwr, setRn_pwr] = useState("");
  const [rn_pwrError, setRn_pwrError] = useState(false);
  const [cs_admin, setCs_admin] = useState("");
  const [cs_adminError, setCs_adminError] = useState(false);
  const [notes, setNOte] = useState("");
  const [notesError, setNOteError] = useState(false);
  const [Loading, setLoading] = useState(false);

  const options = useMemo(() => countryList().getData(), []);

  const coutryChange = (e) => {
    setCountry(e.target.value);
  };

  const handleClick = (e) => {
    if (srvD.length >= 1) {
      setSrv([...srv, srvD]);
      setSrvD("");
    }
  };
  const handleSVRAdd = (e) => {
    setSrvD(e.target.value);
  };
  const deletICon = (index) => {
    let clone = [...srv];
    clone.splice(index, 1);
    setSrv(clone);
  };
  const validateNum = (val) => {
    const pwrRegex = /[0-9]/;
    return pwrRegex.test(val);
  };

  const CreatePsForm = {
    name: name,
    in: In,
    bt_md: bt_md,
    srv: srv,
    status: status,
    contry: country,
    hr: hr,
    rn_type: rn_type,
    cs: cs,
    date_made: date_made,
    pwr: pwr,
    bt_type: bt_type,
    tso: tso,
    test_version: test_version,
    mdl: mdl,
    state: state,
    str_type: str_type,
    rn_pwr: rn_pwr,
    cs_admin: cs_admin,
    notes: notes,
  };

  const navigate = useNavigate();
  const submit = (e) => {
    e.preventDefault();
    if (name.length === 0) {
      toast.error("You must fill all the fields");
      setNameError(true);
      return 0;
    }
    if (!validateNum(pwr)) {
      toast.error("ples enter validate number Pwr");
      setPwrError(true);
      return 0;
    }
    if (In.length === 0) {
      toast.error("You must fill all the fields");
      setInError(true);
      return 0;
    }
    if (!validateNum(hr)) {
      toast.error("ples enter validate number Hr");
      setHrError(true);

      return 0;
    }
    if (test_version.length === 0) {
      toast.error("choose fields Test version");
      setTest_versionError(true);
      return 0;
    }
    if (status.length === 0) {
      toast.error("choose fields Status");
      setStateError(true);
      return 0;
    }
    if (str_type.length === 0) {
      toast.error("choose fields Str-type");
      setStr_typeError(true);
      return 0;
    }
    if (rn_type.length === 0) {
      toast.error("choose fields Rn-pwr");
      setRn_typeError(true);
      return 0;
    }
    if (country === "") {
      toast.error("You must fill all the fields");
      setCountryError(true);
      return 0;
    }
    if (pwr.length === 0) {
      toast.error("You must fill all the fields");
      setPwrError(true);
      return 0;
    }
    if (bt_type.length === 0) {
      toast.error("You must fill all the fields");
      setBt_typeError(true);
      return 0;
    }
    if (bt_md.length === 0) {
      toast.error("You must fill all the fields");
      setBt_mdError(true);
      return 0;
    }
    if (tso.length === 0) {
      toast.error("You must fill all the fields");
      setTsoError(true);
      return 0;
    }
    if (bt_md.country === 0) {
      toast.error("You must fill all the fields");
      return 0;
    }
    if (mdl.length === 0) {
      toast.error("You must fill all the fields");
      setMdlError(true);
      return 0;
    }
    if (state.length === 0) {
      toast.error("You must fill all the fields");
      setStateError(true);
      return 0;
    }
    if (hr.length === 0) {
      toast.error("You must fill all the fields");
      setHrError(true);
      return 0;
    }
    if (rn_pwr.length === 0) {
      toast.error("You must fill all the fields");
      setRn_pwrError(true);
      return 0;
    }
    if (cs.length === 0) {
      toast.error("You must fill all the fields");
      setCsError(true);
      return 0;
    }
    if (cs_admin.length === 0) {
      toast.error("You must fill all the fields");
      setCs_adminError(true);
      return 0;
    }
    if (srv.length === 0) {
      toast.error("You must fill all the fields");
      setSrvError(true);
      return 0;
    }
    if (notes.length === 0) {
      toast.error("You must fill all the fields");
      setNOteError(true);
      return 0;
    }

    setLoading(true);
    axiosApi(
      "api/user/add/ps",
      CreatePsForm,
      localStorage.getItem("admin-token")
    )
      .then((res) => {
        if (res.data.status_code === 200) {
          toast.success(res.data.description);
          setTimeout(() => {
            navigate("/PslistPage");
          }, 1000);
          setLoading(false);
          console.log(CreatePsForm);
        } else {
          toast.error(res.data.description);
          setLoading(false);
        }
      })
      .catch((res) => {
        toast.error("Fail to connect to server");
        setLoading(false);
      });
    console.log(CreatePsForm);
  };
  return (
    <>
      {Loading && <Loader />}
      <div className={CreatePscss.container}>
        <h1>Create Ps</h1>
        <form>
          <div className={CreatePscss.main}>
            <label
              className={`${CreatePscss.Item} ${
                nameError && CreatePscss.borderred
              }`}
            >
              name :
              <input
                type="text"
                className={CreatePscss.ItemIp}
                onChange={(e) => setName(e.target.value)}
                value={name}
                name="name"
                id="name"
                required
              />
            </label>
            <label
              className={`${CreatePscss.Item} ${
                pwrError && CreatePscss.borderred
              }`}
            >
              Pwr :
              <input
                type="number"
                className={CreatePscss.ItemIp}
                onChange={(e) => setPwr(e.target.value)}
                value={pwr}
                name="pwr"
                id="pwr"
                required
              />
            </label>
            <label
              className={`${CreatePscss.Item} ${
                InError && CreatePscss.borderred
              }`}
            >
              In :
              <input
                type="text"
                className={CreatePscss.ItemIp}
                onChange={(e) => setIn(e.target.value)}
                value={In}
                name="In"
                id="In"
                required
              />
            </label>
            <label
              className={`${CreatePscss.Item} ${
                bt_typeError && CreatePscss.borderred
              }`}
            >
              BT Type :
              <input
                type="text"
                className={CreatePscss.ItemIp}
                onChange={(e) => setBt_type(e.target.value)}
                value={bt_type}
                name="btType"
                id="btType"
                required
              />
            </label>
            <label
              className={`${CreatePscss.Item} ${
                bt_mdError && CreatePscss.borderred
              }`}
            >
              BT Md :
              <input
                type="text"
                className={CreatePscss.ItemIp}
                onChange={(e) => setBt_md(e.target.value)}
                value={bt_md}
                name="btMd"
                id="btMd"
                required
              />
            </label>
            <label
              className={`${CreatePscss.Item} ${
                tsoError && CreatePscss.borderred
              }`}
            >
              Tso :
              <input
                type="text"
                className={CreatePscss.ItemIp}
                onChange={(e) => setTso(e.target.value)}
                value={tso}
                name="tso"
                id="tso"
              />
            </label>
            <label
              className={`${CreatePscss.Item} ${
                mdlError && CreatePscss.borderred
              }`}
            >
              Mdl :
              <input
                type="text"
                className={CreatePscss.ItemIp}
                onChange={(e) => setMdl(e.target.value)}
                value={mdl}
                name="mdl"
                id="mdl"
                required
              />
            </label>
            <label
              className={`${CreatePscss.Item} ${
                srvDError && CreatePscss.borderred
              }`}
            >
              Srv :
              <div className={CreatePscss.ItemInputSVG}>
                <input
                  type="text"
                  className={CreatePscss.ItemIp}
                  onChange={(e) => handleSVRAdd(e)}
                  value={srvD}
                  name="srv"
                  id="srv"
                />
                <AiFillPlusCircle
                  className={CreatePscss.AddIcon}
                  onClick={handleClick}
                />
              </div>
            </label>
            {srv.length !== 0 ? (
              <div className={CreatePscss.srvArea}>
                {srv.length !== 0
                  ? srv.map((item, index) => {
                      return (
                        <p key={index} className={CreatePscss.srvItem}>
                          {item}
                          <AiOutlineCloseCircle
                            onClick={() => deletICon(index)}
                          />
                        </p>
                      );
                    })
                  : null}
              </div>
            ) : null}
            <label
              className={`${CreatePscss.Item} ${
                stateError && CreatePscss.borderred
              }`}
            >
              State :
              <input
                type="text"
                className={CreatePscss.ItemIp}
                onChange={(e) => setState(e.target.value)}
                value={state}
                name="state"
                id="state"
                required
              />
            </label>
            <label
              className={`${CreatePscss.Item} ${
                hrError && CreatePscss.borderred
              }`}
            >
              Hr :
              <input
                type="number"
                className={CreatePscss.ItemIp}
                onChange={(e) => setHr(e.target.value)}
                value={hr}
                name="hr"
                id="hr"
                required
              />
            </label>
            <label
              className={`${CreatePscss.Item} ${
                rn_pwrError && CreatePscss.borderred
              }`}
            >
              Rn Pwr :
              <input
                type="text"
                className={CreatePscss.ItemIp}
                onChange={(e) => setRn_pwr(e.target.value)}
                value={rn_pwr}
                name="rnPwr"
                id="rnPwr"
                required
              />
            </label>
            <label
              className={`${CreatePscss.Item} ${
                csError && CreatePscss.borderred
              }`}
            >
              Cs :
              <input
                type="text"
                className={CreatePscss.ItemIp}
                onChange={(e) => setCs(e.target.value)}
                value={cs}
                name="cs"
                id="cs"
                required
              />
            </label>
            <label
              className={`${CreatePscss.Item} ${
                cs_adminError && CreatePscss.borderred
              }`}
            >
              Cs admin :
              <input
                type="text"
                className={CreatePscss.ItemIp}
                onChange={(e) => setCs_admin(e.target.value)}
                value={cs_admin}
                name="csAdmin"
                id="csAdmin"
                required
              />
            </label>
            <label
              className={`${CreatePscss.Item} ${
                date_madeError && CreatePscss.borderred
              }`}
            >
              Date Made :
              <input
                type="date"
                className={CreatePscss.ItemIp}
                onChange={(e) => setDate_made(e.target.value)}
                value={date_made}
                name="dateMade"
                id="dateMade"
                required
              />
            </label>
            <label
              className={`${CreatePscss.Item} ${
                str_typeError && CreatePscss.borderred
              }`}
            >
              Str Type :
              <select
                name="strType"
                id="strType"
                value={str_type}
                className={CreatePscss.ItemS}
                onChange={(e) => setStr_type(e.target.value)}
                defaultValue="choose"
              >
                <option value="choose" hidden>
                  Choose
                </option>
                <option>L</option>
                <option>S</option>
              </select>
            </label>
            <label
              className={`${CreatePscss.Item} ${
                rn_typeError && CreatePscss.borderred
              }`}
            >
              Rn Type :
              <select
                name="rnType"
                id="rnType"
                className={CreatePscss.ItemS}
                value={rn_type}
                onChange={(e) => setRn_type(e.target.value)}
                defaultValue="choose"
              >
                <option value="choose" hidden>
                  Choose
                </option>
                <option value="W">W</option>
                <option value="P">P</option>
                <option value="O">O</option>
              </select>
            </label>
            <label
              className={`${CreatePscss.Item} ${
                countryError && CreatePscss.borderred
              }`}
            >
              Country :
              {/* <Select
                options={options}
                value={country}
                onChange={coutryChange}
                className={CreatePscss.ItemSC}
              /> */}
              <select
                className={CreatePscss.ItemS}
                onChange={(e) => coutryChange(e)}
                defaultValue="choose"
              >
                <option value="choose" hidden>
                  Choose
                </option>
                {options.map((item, index) => {
                  return (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  );
                })}
              </select>
            </label>
            <label
              className={`${CreatePscss.Item} ${
                test_versionError && CreatePscss.borderred
              }`}
            >
              Test Version :
              <select
                className={CreatePscss.ItemS}
                onChange={(e) => setTest_version(e.target.value)}
                value={test_version}
                defaultValue="Choose"
              >
                <option value="choose" hidden>
                  Choose
                </option>
                <option value="No Version">No Version </option>
                <option value="1.2.3.4">1.2.3.4 </option>
                <option value="4.3.2.1">4.3.2.1 </option>
                <option value="6.5.4.3">6.5.4.3 </option>
              </select>
            </label>
            <label
              className={`${CreatePscss.Item} ${
                statusError && CreatePscss.borderred
              }`}
            >
              Status :
              <select
                name="status"
                id="status"
                className={CreatePscss.ItemS}
                onChange={(e) => setStatus(e.target.value)}
                value={status}
                defaultValue="Choose"
              >
                <option value="choose" hidden>
                  Choose
                </option>
                <option>Up </option>
                <option>Down </option>
                <option>Maintenance </option>
                <option>Dvlp </option>
              </select>
            </label>

            <div className={CreatePscss.mainC}>
              <span>Notes :</span>
              <textarea
                className={CreatePscss.TextAreaIP}
                onChange={(e) => setNOte(e.target.value)}
                name="notes"
                id="notes"
                value={notes}
                required
              ></textarea>
              <input
                type="submit"
                onClick={submit}
                className={CreatePscss.submitIn}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
