import React, { useEffect, useState } from "react";
import TestListCSS from "./Test-List.module.css";
import { axiosApi } from "../../defz";
import { Link } from "react-router-dom";
import { AiFillPlusCircle } from "react-icons/ai";
import { AiFillCaretDown } from "react-icons/ai";
import Loader from "../../components/Loader/Loader";
import { Pagination } from "@mui/material";
import { toast } from "react-hot-toast";

export default function TestList() {
  const [TestData, setTestData] = useState([]);
  const [load, setLoad] = useState(false);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState();
  const [lightColor, setLightColor] = useState();

  const fetch = () => {
    setLoad(true);
    axiosApi(
      "api/user/get/test",
      { number: page },
      localStorage.getItem("admin-token")
    )
      .then((res) => {
        setLoad(false);
        setTestData(res.data.info);
        setMaxPage(res.data.max_page);
      })
      .catch((res) => toast.error("Fail to connect to server"));
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    let themeColorVar = localStorage.getItem("DashboardThemeColor");
    switch (themeColorVar) {
      case "blue":
        setLightColor("#C9EEFF");
        break;
      case "green":
        setLightColor("#B5F1CC");
        break;
      case "purple":
        setLightColor("#E5D1FA");
        break;
      case "red":
        setLightColor("#f8cdde");
        break;
      default:
        break;
    }
  }, []);
  const handleChange = (e, p) => {
    setPage(p);
  };

  const handleStatus = (id, status) => {
    axiosApi(
      "api/up/del",
      {
        col: "test",
        action: "update",
        id: id,
        state: status === "verified" ? "verify" : "verified",
      },
      localStorage.getItem("admin-token")
    ).then((res) => fetch());
  };

  return (
    <div className={TestListCSS.container}>
      {load && <Loader />}
      <h1>Tests</h1>
      <div className={TestListCSS.items}>
        <div className={TestListCSS.addBtn}>
          <Link to="/add-test">
            <AiFillPlusCircle /> Add New Test
          </Link>
        </div>
        <table>
          <thead>
            <tr className={TestListCSS.tableHead}>
              <th className={TestListCSS.mainTitleRow}>
                <div>
                  No
                  <AiFillCaretDown color="gray" />
                </div>
              </th>
              <th className={TestListCSS.mainTitleRow}>
                <div>
                  Version
                  <AiFillCaretDown color="gray" />
                </div>
              </th>
              <th className={TestListCSS.mainTitleRow}>
                <div>
                  Created
                  <AiFillCaretDown color="gray" />
                </div>
              </th>
              <th className={TestListCSS.mainTitleRow}>
                <div>
                  State
                  <AiFillCaretDown color="gray" />
                </div>
              </th>
              <th className={TestListCSS.mainTitleRow}>
                <div>
                  Ps
                  <AiFillCaretDown color="gray" />
                </div>
              </th>
              <th className={TestListCSS.mainTitleRow}>
                <div>
                  RN
                  <AiFillCaretDown color="gray" />
                </div>
              </th>
              <th className={TestListCSS.mainTitleRow}>
                <div>
                  RN2
                  <AiFillCaretDown color="gray" />
                </div>
              </th>
              <th className={TestListCSS.mainTitleRow}>
                <div>
                  Type
                  <AiFillCaretDown color="gray" />
                </div>
              </th>
              <th className={TestListCSS.mainTitleRow}>
                <div>
                  Files
                  <AiFillCaretDown color="gray" />
                </div>
              </th>
              <th className={TestListCSS.mainTitleRow}>
                <div>
                  Action
                  <AiFillCaretDown color="gray" />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {TestData.map((item, index) => {
              return (
                <tr key={index} className={TestListCSS.row}>
                  <th className={TestListCSS.info}>{index + 1}</th>
                  <th className={TestListCSS.info}>{item.version}</th>
                  <th className={TestListCSS.info}>{item.time.slice(0, 16)}</th>
                  <th className={TestListCSS.info}>{item.state}</th>
                  <th className={TestListCSS.info}>{item.ps}</th>
                  <th className={TestListCSS.info}>
                    <Link to={item.RN1}>{item.RN1.split("/").slice(-1)}</Link>
                  </th>
                  <th className={TestListCSS.info}>
                    <Link to={item.RN2}>{item.RN2.split("/").slice(-1)}</Link>
                  </th>
                  <th className={TestListCSS.info}>{item.type}</th>
                  <th className={TestListCSS.info}>
                    <Link to={item.file}>{item.file.split("/").slice(-1)}</Link>
                  </th>
                  <th className={TestListCSS.infoBtn}>
                    <button
                      onClick={() => handleStatus(item._id, item.state)}
                      disabled={
                        localStorage.getItem("type") === "0" || "1"
                          ? false
                          : true
                      }
                      style={{
                        backgroundColor:
                          item.state === "verified" ? "#29CC39" : "#FFD93D",
                      }}
                    >
                      {item.state}
                    </button>
                  </th>
                </tr>
              );
            })}
          </tbody>
        </table>
        <footer className={TestListCSS.Pagination}>
          <Pagination count={maxPage} color="primary" onChange={handleChange} />
        </footer>
      </div>
    </div>
  );
}
