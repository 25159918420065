import React, { useEffect, useState } from "react";
import linegrapghCss from "./LineGraphPs.module.css";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { NavLink } from "react-router-dom";
import { axiosApi } from "../../defz";
import Loader from "../Loader/Loader";
import { toast } from "react-hot-toast";
import { FaFileDownload } from "react-icons/fa";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

export default function LineGraphPs() {
  const [reportPsData, setReportPsData] = useState([]);
  const [reportPsCount, setReportPsCount] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [link, setLink] = useState("");

  const exportHandeling = () => {
    axiosApi(
      "api/excel",
      {
        type: "ps",
      },
      localStorage.getItem("admin-token")
    )
      .then((res) => {
        toast.success("success");
        console.log(res);
        setLink(res.data.link);
      })
      .catch((res) => {
        toast.error("server Err");
      });
  };

  useEffect(() => {
    setLoading(true);
    axiosApi("api/report/finder/ps", "", localStorage.getItem("admin-token"))
      .then((res) => {
        setReportPsData(res.data.data);
        setReportPsCount(res.data.count);
        setLoading(false);
      })
      .catch((res) => {
        setLoading(false);
        toast.error("failed to connect to server");
      });
  }, []);
  const state = {
    labels: reportPsData,
    datasets: [
      {
        label: "Rainfall",
        fill: false,
        lineTension: 0.5,
        backgroundColor: "rgba(75,192,192,1)",
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        barPercentage: 0.1,
        borderRadius: 10,
        data: reportPsCount,
      },
    ],
  };
  return (
    <div className={linegrapghCss.container}>
      {Loading && <Loader />}
      <h1>Reports</h1>
      <div className={linegrapghCss.main}>
        <div className={linegrapghCss.stats}>
          <span className={linegrapghCss.item}>
            <NavLink to="/LinegraphPsPage">Stat 1</NavLink>
          </span>
          <span className={linegrapghCss.item}>
            <NavLink to="/ReportCountriesPage">Stat 2</NavLink>
          </span>
          <span className={linegrapghCss.item}>
            <NavLink to="/ReportsStatusPage">Stat 3</NavLink>
          </span>
          <span className={linegrapghCss.item}>
            <NavLink to="/versionReports">Stat 4</NavLink>
          </span>
          <span className={linegrapghCss.item}>
            <NavLink to="/PwrReports">Stat 5</NavLink>
          </span>
          <span className={linegrapghCss.item}>
            <NavLink to="/svrReports">Stat 6</NavLink>
          </span>
          
        </div>
        <div className={linegrapghCss.chartMain}>
          <div className={linegrapghCss.chartHead}>
            <h2>Ps</h2>
            <NavLink
              to={link}
              className={linegrapghCss.exportIcon}
              onClick={exportHandeling}
            >
              Export
              <FaFileDownload size="20px" />
            </NavLink>
          </div>

          <div className={linegrapghCss.barChart}>
            <Bar data={state}
              width={150}
              height={70}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
