import { SidebarData } from "./SidebarData";
import React, { useState, useEffect } from "react";
import SidebarCSS from "./Sidebar.module.css";
import SidebarChart from "../Stats/Stats";
import PsInfo from "../Ps-Info/Ps-Info";
import MembersInf from "../MembersInf/MembersInf";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { BiLogOut } from "react-icons/bi";
import { BsFillPatchQuestionFill } from "react-icons/bs";
import { IoIosCloseCircle } from "react-icons/io";
import { IoMdColorPalette } from "react-icons/io";

export default function Sidebar(props) {
  const navigate = useNavigate();
  const loc = useLocation();

  const [isShowModal, setIsShowModal] = useState(false);

  const [sidebar, setSidebar] = useState(true);
  const [showMenu, setShowMenu] = useState(false);

  const [darkColor, setDarkColor] = useState();
  const [lightColor, setLightColor] = useState();

  const showSidebar = () => setSidebar(!sidebar);

  // useEffect(() => {
  //   let themeColor = localStorage.getItem('DashboardThemeColor')
  //   switch (themeColor) {
  //     case 'blue':
  //       setDarkColor('#0f0044')
  //       setLightColor('#400bff')
  //       break;
  //     case 'green':
  //       setDarkColor('#003618')
  //       setLightColor('#04bf1d')
  //       break;
  //     case 'red':
  //       setDarkColor('#360000')
  //       setLightColor('#bf0442')
  //       break;
  //     case 'purple':
  //       setDarkColor('#240044')
  //       setLightColor('#990bff')
  //       break;
  //     case 'gold':
  //       setDarkColor('#FFD93D')
  //       setLightColor('#FF6000')
  //       break;
  //     default:
  //       break;
  //   }
  // }, [])

  const logout = () => {
    setIsShowModal(true);
  };
  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };
  const themeColorHandler = (theme) => {
    switch (theme) {
      case "Gr":
        localStorage.setItem("DashboardThemeColor", "green");
        break;
      case "B":
        localStorage.setItem("DashboardThemeColor", "blue");
        break;
      case "R":
        localStorage.setItem("DashboardThemeColor", "red");
        break;
      case "P":
        localStorage.setItem("DashboardThemeColor", "purple");
        break;
      case "Go":
        localStorage.setItem("DashboardThemeColor", "gold");
        break;
      default:
        localStorage.setItem("DashboardThemeColor", "blue");
        break;
    }
    refreshPage();
  };
  const refreshPage = () => {
    // navigate(loc.pathname)
    window.location.reload();
  };

  // window.addEventListener('click', () => {
  //   if (isShowModal) {
  //     setIsShowModal(false)
  //   }
  // })

  return (
    <>
      {/* <div className={SidebarCSS.navBar}>
        <Link to="#" className={SidebarCSS.menuBars}>
          <FontAwesomeIcon icon={faBars} onClick={showSidebar} />
        </Link>
      </div> */}
      <nav
        style={{
          borderRadius: "20px",
          background: "#313649",
          boxShadow:
            "5px 5px 13px 0px rgba(195, 195, 195, 0.90), -5px -5px 10px 0px rgba(239, 239, 239, 0.90), 5px -5px 10px 0px rgba(195, 195, 195, 0.20), -5px 5px 10px 0px rgba(195, 195, 195, 0.20), -1px -1px 2px 0px rgba(195, 195, 195, 0.50) inset, 1px 1px 2px 0px rgba(239, 239, 239, 0.30) inset",
        }}
        className={
          sidebar
            ? `${SidebarCSS.navMenu} ${SidebarCSS.navMenuActive}`
            : SidebarCSS.navMenu
        }
      >
        {/* <div className={SidebarCSS.colorBox}>
          <IoMdColorPalette onClick={() => { setShowMenu(pre => !pre) }} />
          <div className={showMenu ? `${SidebarCSS.palette} ${SidebarCSS.active}` : `${SidebarCSS.palette}`}>
            <div
              className={SidebarCSS.colorItem}
              style={{ background: 'red' }}
              onClick={() => {
                themeColorHandler('R')
                setShowMenu(false)
              }}
            >
            </div>
            <div
              className={SidebarCSS.colorItem}
              style={{ background: 'purple' }}
              onClick={() => { themeColorHandler('P'); setShowMenu(false) }}> </div>
            <div
              className={SidebarCSS.colorItem}
              style={{ background: 'green' }}
              onClick={() => { themeColorHandler('Gr'); setShowMenu(false) }}> </div>
            <div
              className={SidebarCSS.colorItem}
              style={{ background: 'blue' }}
              onClick={() => { themeColorHandler('B'); setShowMenu(false) }}> </div>
            <div
              className={SidebarCSS.colorItem}
              style={{ background: 'orange' }}
              onClick={() => { themeColorHandler('Go'); setShowMenu(false) }}> </div>
          </div>
          <span className={SidebarCSS.colorText} onClick={() => setShowMenu(true)}>Change Theme</span>
        </div> */}
        {/* <div className={SidebarCSS.btn}></div> */}
        {/* <div className={SidebarCSS.navBarToggle}>
        </div> */}
        <ul className={SidebarCSS.navMenu_items} onClick={showSidebar}>
          <li className={SidebarCSS.navBarToggle}></li>
          {SidebarData.map((item, index) => {
            return (
              <li key={index} className={SidebarCSS.navText}>
                <NavLink to={item.path} className={SidebarCSS.Link}>
                  {item.icon}
                  <span className={SidebarCSS.title}>{item.title}</span>
                </NavLink>
              </li>
            );
          })}
        </ul>
        {props.page === "items" ? <PsInfo /> : null}
        {props.page === "pslist" ? <SidebarChart /> : null}
        {props.page === "tests" ? <SidebarChart page={"tests"} /> : null}
        {props.page === "reports" ? <SidebarChart page={"reports"} /> : null}
        {props.page === "tasks" ? <MembersInf /> : null}
        {props.page === "activity" ? <MembersInf /> : null}
        {props.page === "members" ? <MembersInf /> : null}

        <div className={SidebarCSS.logOutBtn} onClick={logout}>
          <BiLogOut />
        </div>
      </nav>

      <section
        className={
          isShowModal
            ? `${SidebarCSS.modalContainer} ${SidebarCSS.showModalContainer}`
            : SidebarCSS.modalContainer
        }
      >
        <div className={SidebarCSS.modal}>
          <IoIosCloseCircle
            className={SidebarCSS.closeBtn}
            onClick={() => setIsShowModal(false)}
          />
          <BsFillPatchQuestionFill className={SidebarCSS.question_icon} />
          <h2>Are you sure?</h2>
          <div className={SidebarCSS.btn_container}>
            <button onClick={() => setIsShowModal(false)}>No</button>
            <button
              onClick={() => {
                setIsShowModal(false);
                handleLogout();
              }}
            >
              Yes, logout!
            </button>
          </div>
        </div>
      </section>
    </>
  );
}
