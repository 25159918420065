import React from "react";
import { Link } from "react-router-dom";
import LoginformCSS from "./Login.module.css";
import TextField from '@mui/material/TextField';

export default function ChangePass() {
  return (
    <div className={LoginformCSS.container}>
      <h1>Reset your password</h1>
      <TextField className={LoginformCSS.inputs} id="outlined-basic" label="password" variant="outlined" />
      <TextField className={LoginformCSS.inputs} id="outlined-basic" label="Re-enter password" variant="outlined" />
      <button className={LoginformCSS.submitBtn}>Continue</button>
      <Link id="link" to="/dashboard">
        To Dashboard
      </Link>
      <p>Field must have same passwords</p>
    </div>
  );
}
