import React from "react";
import SnakeCss from "./Addstage.module.css";

function SnakeRowComp({ data, direction }) {
  function getMonthName(Number) {
    const month = Number.split("-")[1];
    const day = Number.split("-")[2];
    const year = Number.split("-")[0];
    const date = new Date();
    date.setMonth(month - 1);
    const monthName = date.toLocaleString("en-US", {
      month: "long",
    });

    const fulllYear = `${monthName}  ${day} ${year}`;
    return fulllYear;
  }
  return (
    <div
      className={
        direction
          ? `${SnakeCss.snakeRow} ${SnakeCss.snakeRowLtr}`
          : `${SnakeCss.snakeRow} ${SnakeCss.snakeRowRtl}`
      }
    >
      {/* {data.length !== 0
        ? data.map((item, index) => {
            return <div key={index}>{item.count_time}</div>;
          })
        : ""}*/}
      {data.length !== 0
        ? data.map((item, index) => {
            return (
              <div className={SnakeCss.sheduleData} key={index}>
                <p> {getMonthName(item.date)} </p>
                <div className={SnakeCss.dataCount}>{item.count_time}</div>
                <p>{item.version}</p>
              </div>
            );
          })
        : ""}
    </div>
  );
}

export default SnakeRowComp;
