import React from "react";
import EnterEmail from "../../components/Login/Enter-Email";

export default function EnterEmailPage() {
  return (
    <div>
      <EnterEmail />
    </div>
  )
}
