import React, { useEffect, useState } from "react";
import MembersListCSS from "./Members-List.module.css";
import { axiosApi } from "../../defz";
import { Link } from "react-router-dom";
import { AiFillPlusCircle } from "react-icons/ai";
import { AiFillCaretDown } from "react-icons/ai";
import Loader from "../../components/Loader/Loader";
import { AiOutlineClose } from "react-icons/ai";
import { AiOutlineSearch } from "react-icons/ai";
import { Pagination } from "@mui/material";
import { toast } from "react-hot-toast";
import { BsFillPatchQuestionFill } from "react-icons/bs";
import { IoIosCloseCircle } from "react-icons/io";

export default function MembersList() {
  const [membersData, setMembersData] = useState([]);
  const [load, setLoad] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedId, setSelectedId] = useState("");
  const [modal, setModal] = useState(false);
  const [maxPage, setMaxPage] = useState();
  const [search, setSearch] = useState("");
  const [searchStatus, setSearchStatus] = useState(false);
  const [lightColor, setLightColor] = useState();

  const fetch = () => {
    setLoad(true);
    axiosApi(
      "api/user/get/member",
      {
        number: page,
      },
      localStorage.getItem("admin-token")
    )
      .then((res) => {
        setLoad(false);
        setMembersData(res.data.info);
        setMaxPage(res.data.max_page);
      })
      .catch((res) => toast.error("Cannot connect to server"));
  };

  useEffect(() => {
    let themeColorVar = localStorage.getItem("DashboardThemeColor");
    switch (themeColorVar) {
      case "blue":
        setLightColor("#C9EEFF");
        break;
      case "green":
        setLightColor("#B5F1CC");
        break;
      case "purple":
        setLightColor("#E5D1FA");
        break;
      case "red":
        setLightColor("#f8cdde");
        break;
      case "gold":
        setLightColor("#FFDEB4");
        break;
      default:
        break;
    }
  });
  useEffect(() => {
    fetch();
    // eslint-disable-next-line
  }, [page]);

  const handleChange = (e, p) => {
    setPage(p);
  };

  const handleStatus = (id, status) => {
    axiosApi(
      "api/up/del",
      {
        col: "user",
        action: "update",
        id: id,
        status: status === true ? false : true,
      },
      localStorage.getItem("admin-token")
    ).then((res) => fetch());
  };

  const handleDelete = (id) => {
    axiosApi(
      "api/up/del",
      {
        col: "user",
        action: "delete",
        id: id,
      },
      localStorage.getItem("admin-token")
    ).then((res) => fetch());
  };

  const handleSearch = () => {
    setLoad(true);
    setSearchStatus(!searchStatus);
    if (!searchStatus) {
      axiosApi(
        "api/user/get/member",
        {
          number: page,
          search: search,
        },
        localStorage.getItem("admin-token")
      )
        .then((res) => {
          console.log("false");
          setLoad(false);
          setMembersData(res.data.info);
          setMaxPage(res.data.max_page);
        })
        .catch((res) => toast.error("Cannot connect to server"));
    } else {
      setLoad(true);
      axiosApi(
        "api/user/get/member",
        {
          number: page,
        },
        localStorage.getItem("admin-token")
      )
        .then((res) => {
          setLoad(false);
          setSearch("");
          setMembersData(res.data.info);
          setMaxPage(res.data.max_page);
        })
        .catch((res) => toast.error("Cannot connect to server"));
    }
  };

  return (
    <div className={MembersListCSS.container}>
      {load && <Loader />}
      <div className={MembersListCSS.header}>
        <h1>Members</h1>
        <div className={MembersListCSS.searchBar}>
          <input
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search"
          />
          <button onClick={handleSearch}>
            {searchStatus ? (
              <AiOutlineClose size="24" />
            ) : (
              <AiOutlineSearch size="30" />
            )}
          </button>
        </div>
      </div>
      <div className={MembersListCSS.items}>
        <div className={MembersListCSS.addBtn}>
          <Link to="/add-members">
            <AiFillPlusCircle />
            Add Member
          </Link>
        </div>
        <div
          className={
            modal
              ? `${MembersListCSS.modalBox} ${MembersListCSS.showModalBox}`
              : MembersListCSS.modalBox
          }
        >
          <div className={MembersListCSS.modal}>
            <IoIosCloseCircle
              className={MembersListCSS.closeBtn}
              onClick={() => setModal(false)}
            />
            <BsFillPatchQuestionFill className={MembersListCSS.question_icon} />
            <h2>Are you sure you want to delete this user?</h2>
            <div className={MembersListCSS.btn_container}>
              <button onClick={() => setModal(false)}>No</button>
              <button
                onClick={() => {
                  setModal(false);
                  handleDelete(selectedId);
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
        <table>
          <thead>
            <tr className={MembersListCSS.tableHead}>
              <th>
                <div>
                  No
                  <AiFillCaretDown color="gray" />
                </div>
              </th>
              <th>
                <div>
                  Name
                  <AiFillCaretDown color="gray" />
                </div>
              </th>
              <th>
                <div>
                  Added
                  <AiFillCaretDown color="gray" />
                </div>
              </th>
              <th>
                <div>
                  State
                  <AiFillCaretDown color="gray" />
                </div>
              </th>
              <th>
                <div>
                  Type
                  <AiFillCaretDown color="gray" />
                </div>
              </th>
              <th>
                <div>
                  Email
                  <AiFillCaretDown color="gray" />
                </div>
              </th>
              <th>
                <div>
                  Verified
                  <AiFillCaretDown color="gray" />
                </div>
              </th>
              <th>
                <div>
                  Organization / Action
                  <AiFillCaretDown color="gray" />
                </div>
              </th>
              
            </tr>
          </thead>
          <tbody>
            {membersData.map((item, index) => {
              return (
                <tr key={index} className={MembersListCSS.row}>
                  <th
                    className={`${MembersListCSS.info} ${MembersListCSS.num}`}
                  >
                    {index + 1}
                  </th>
                  <th className={MembersListCSS.info}>{item.user}</th>
                  <th className={MembersListCSS.info}>{item.time.slice(0, item.time.indexOf('.'))}</th>
                  <th
                    className={
                      item.status
                        ? `${MembersListCSS.Active} ${MembersListCSS.info}`
                        : `${MembersListCSS.not_active} ${MembersListCSS.info}`
                    }
                  >
                    {item.status === true ? "Active" : "not active"}
                  </th>
                  <th className={MembersListCSS.info}>
                    {item.type === 0 ? "Admin" : item.type}
                  </th>
                  <th className={MembersListCSS.info}>{item.email}</th>
                  <th className={MembersListCSS.info}>
                    {item.verfiyed_stated}
                  </th>
                  {/* <th className={MembersListCSS.info}>{item.organizartion}</th> */}
                  <th className={MembersListCSS.infoBtn}>
                    <button
                      onClick={() => handleStatus(item._id, item.status)}
                      className={
                        item.status === true
                          ? MembersListCSS.Deactivate
                          : MembersListCSS.Activate
                      }
                    >
                      {item.status === true ? "Deactivate" : "Activate"}
                    </button>
                    <button
                      onClick={() => {
                        setModal(true);
                        setSelectedId(item._id);
                      }}
                    >
                      Delete
                    </button>
                  </th>
                 
                </tr>
              );
            })}
          </tbody>
        </table>
        <footer className={MembersListCSS.Pagination}>
          <Pagination count={maxPage} color="primary" onChange={handleChange} />
        </footer>
      </div>
    </div>
  );
}
