import React, { useEffect, useState } from "react";
import ItemCSS from "./Item.module.css";
import { axiosApi } from "../../defz";
import toast from "react-hot-toast";
import Loader from "../../components/Loader/Loader";
import { AiOutlineOrderedList } from "react-icons/ai";
import { BsFillPeopleFill } from "react-icons/bs";
import { AiFillFlag } from "react-icons/ai";
import { AiFillInfoCircle } from "react-icons/ai";
import { AiFillCaretDown } from "react-icons/ai";
import { BsFillPersonFill } from "react-icons/bs";
import { Link, useNavigate, useParams } from "react-router-dom";

export default function Item() {
  const EDIT_PS = "api/user/edit/ps";
  const DOWNLOAD_API = "api/download/route";

  const { id } = useParams();

  const [load, setLoad] = useState(false);
  const [psData, setPsData] = useState();
  const [adbtn, setadBtn] = useState(false);

  const getPsInfo = () => {
    setLoad(true);
    axiosApi(
      "api/user/get/ps_one",
      { psid: id },
      localStorage.getItem("admin-token")
    )
      .then((res) => {
        setLoad(false);
        setPsData(res.data.data[0]);
        setadBtn(res.data.data[0].automatic_report);
        // console.log(res.data.data[0].srv)
      })
      .catch((res) => toast.error("Fail to connect to server"));
  };

  useEffect(() => {
    getPsInfo();
    // eslint-disable-next-line
  }, []);

  const handleTaskStatus = (id, status) => {
    setLoad(true);
    axiosApi(
      "api/up/del",
      {
        col: "tasks",
        action: "update",
        id: id,
        status: status === "pending" ? "Done" : "pending",
      },
      localStorage.getItem("admin-token")
    ).then((res) => {
      setLoad(false);
      getPsInfo();
    });
  };

  const handleIssueStatus = (id, status) => {
    setLoad(true);
    axiosApi(
      "api/up/del",
      {
        col: "iteam-issue",
        action: "update",
        id: id,
        status: status === "Resolve" ? "Resolved" : "Resolve",
      },
      localStorage.getItem("admin-token")
    ).then((res) => {
      setLoad(false);
      getPsInfo();
    });
  };

  const handleChange = (e) => {
    if (e.target.value === "on") {
      axiosApi(
        EDIT_PS,
        {
          U: true,
          psid: id,
        },
        localStorage.getItem("admin-token")
      ).then((res) => {
        getPsInfo();
      });
    } else {
      axiosApi(
        EDIT_PS,
        {
          U: false,
          psid: id,
        },
        localStorage.getItem("admin-token")
      ).then((res) => {
        console.log(res);
        getPsInfo();
      });
    }
  };

  const completeU = () => {
    if (psData.U === "complete") return;
    axiosApi(
      EDIT_PS,
      {
        U: "complete",
        psid: id,
      },
      localStorage.getItem("admin-token")
    ).then((res) => {
      console.log(res);
      getPsInfo();
    });
  };

  const downloadApi = () => {
    axiosApi(DOWNLOAD_API, {}, localStorage.getItem("admin-token")).then(
      (res) => {
        if (res.data.status_code === 200) {
          window.open(res.data.link);
        }
      }
    );
  };

  const handlePsAction = (type) => {
    setLoad(true);
    axiosApi(
      `api/slider/${type}`,
      { psid: id },
      localStorage.getItem("admin-token")
    ).then((res) => {
      if (res.data.status_code === 200) {
        setLoad(false);
        toast.success("Done!");
        getPsInfo();
      } else {
        setLoad(false);
        toast.error(res.data.description);
      }
    });
  };

  const activeDeactivehandler = () => {
    axiosApi(
      `api/user/admin/automatic_report`,
      { psid: id, status: !adbtn },
      localStorage.getItem("admin-token")
    ).then((res) => getPsInfo());
  };
  return (
    <div
      className={ItemCSS.container}
      style={{ width: "100%", marginTop: "0" }}
    >
      {load && <Loader />}
      <h1 className={ItemCSS.title}>PS name : {psData?.name}</h1>
      <div className={ItemCSS.items}>
        <aside>
          <div className={ItemCSS.ver}>
            <em>Version : </em>
            <p>{psData?.test_version}</p>
          </div>
          <div className={ItemCSS.U}>
            <p>U</p>
            <input
              type="checkbox"
              checked={psData?.U}
              disabled={psData?.U === "complete"}
              onChange={handleChange}
            />
            <p>1.2.3.4</p>
            {psData?.U === "complete" ? (
              ""
            ) : (
              <button onClick={completeU}>Complete</button>
            )}
          </div>
          <div className={ItemCSS.cal}>
            <em>Oout : </em>
            <p>{psData?.date_made}</p>
          </div>
          <div className={ItemCSS.noSer}>
            <em>Number of services : </em>
            <p>12</p>
          </div>
          {/* <div className={ItemCSS.ser}>
            {psData?.srv.map((item, index) => {
              return (
                 <button key={index} className={ItemCSS.serBtn}>
                   {item}
                 </button>
              );
            })}
          </div> */}
          <div className={ItemCSS.autoR}>
            <h4>Automatic report</h4>
            <div>
              <button onClick={downloadApi}>Download API</button>
              <button
                onClick={() => {
                  setadBtn(!adbtn);
                  activeDeactivehandler();
                }}
              >
                {adbtn ? "Deactivate PS" : "Active PS"}
              </button>
            </div>
          </div>
          <div className={ItemCSS.manR}>
            <h4>
              Manual Report ({" "}
              {psData?.work?.Down
                ? "Down"
                : psData?.work?.UP
                ? "UP"
                : psData?.work?.maintenace
                ? "maintenace"
                : ""}
              )
            </h4>
            <div className={ItemCSS.btnBox}>
              <button onClick={() => handlePsAction("down")}>Down</button>
              <button onClick={() => handlePsAction("up")}>Up</button>
            </div>
            <button
              className={ItemCSS.maintenanceBtn}
              onClick={() => handlePsAction("maintenace")}
            >
              Maintenance
            </button>
          </div>
        </aside>
        <section className={ItemCSS.main}>
          <div className={ItemCSS.row1}>
            <div className={ItemCSS.boxContainer}>
              <div className={ItemCSS.Box}>
                <div
                  className={ItemCSS.iconWithCount}
                  style={{ width: "fit-content" }}
                >
                  <AiOutlineOrderedList size="24" fill="#fff" />
                  <span style={{ width: "fit-content", paddingRight: "10px" }}>
                    {psData?.task.length} Tasks
                  </span>
                </div>
                <div className={ItemCSS.link}>
                  <Link to={`/item-tasks/${id}`}>See All</Link>
                </div>
                {psData?.task.map((item, index) => {
                  return (
                    <div key={index} className={ItemCSS.tasksItems}>
                      <p>{index + 1}</p>
                      <p style={{ color: "#252525", background: "#ccc" }}>
                        {item.task}
                      </p>
                      <button
                        className={
                          item.status === "pending"
                            ? `${ItemCSS.statusBtn} ${ItemCSS.statusBtnDone}`
                            : ItemCSS.statusBtn
                        }
                        onClick={
                          item.status === "Done"
                            ? null
                            : () => handleTaskStatus(item._id, item.status)
                        }
                        disabled={
                          localStorage.getItem("type") === "0" || "1"
                            ? false
                            : true
                        }
                      >
                        {item.status}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={ItemCSS.boxContainer}>
              <div className={ItemCSS.Box}>
                <div
                  className={ItemCSS.iconWithCount}
                  style={{ width: "fit-content" }}
                >
                  <BsFillPeopleFill size="24" fill="#fff" />
                  <span style={{ width: "fit-content", paddingRight: "10px" }}>
                    {psData?.chat.length} chat
                  </span>
                </div>
                {psData?.chat.reverse().map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        item.sender === localStorage.getItem("id")
                          ? `${ItemCSS.commentsItems}  ${ItemCSS.commentsItemsME}`
                          : `${ItemCSS.commentsItems}  ${ItemCSS.commentsItemsOther}`
                      }
                    >
                      <p>{item?.user_data?.user}</p>
                      <p>{item.msg}</p>
                    </div>
                  );
                })}
                <div className={ItemCSS.link}>
                  <Link to={`/item-chats/${id}`}>See All</Link>
                </div>
              </div>
            </div>
          </div>

          <div className={ItemCSS.row2}>
            <div className={ItemCSS.boxContainer}>
              <div className={ItemCSS.iconWithCount}>
                <AiFillFlag size="24" fill="#fff" />
                <span style={{ width: "fit-content", paddingRight: "10px" }}>
                  {psData?.test.length} Test
                </span>
              </div>
              <div className={ItemCSS.Box}>
                {psData?.test.map((item, index) => {
                  return (
                    <div key={index} className={ItemCSS.issuesItems}>
                      <p>{`${index + 1}`}</p>
                      <p>{item.state}</p>
                      <p>{item.type}</p>
                      <p>{item.version}</p>
                    </div>
                  );
                })}
                <div className={ItemCSS.link}>
                  <Link to={`/item-test/${id}`}>See All</Link>
                </div>
              </div>
            </div>
          </div>
          <div className={ItemCSS.row3}>
            <div className={ItemCSS.boxContainer}>
              <div className={ItemCSS.iconWithCount}>
                <AiFillFlag size="24" fill="#fff" />
                <span style={{ width: "fit-content", paddingRight: "10px" }}>
                  {psData?.issue.length} issue
                </span>
              </div>
              <div className={ItemCSS.Box}>
                {psData?.issue.map((item, index) => {
                  return (
                    <div key={index} className={ItemCSS.issuesItems}>
                      <p>{`${index + 1}`}</p>
                      <p>{item.state}</p>
                      <p>{item.type}</p>
                      <p>{item.version}</p>
                    </div>
                  );
                })}
                <div className={ItemCSS.link}>
                  <Link to={`/item-issues/${id}`}>See All</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

// /api/user/admin/automatic_report
// Auth-token——>header
// psid
// status
