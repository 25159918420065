import React, { useEffect } from "react";
import { Routes, Route, useLocation, useRoutes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import "./styles.css";
import LoginPage from "./pages/login/LoginPage";
import EnterEmailPage from "./pages/login/EnterEmailPage";
import ChangePassPage from "./pages/login/ChangePassPage";
import ItemsPage from "./pages/Items/ItemsPage";
import ItemsTasksPage from "./pages/Items/itemsTasksPage";
import ItemsIssuesPage from "./pages/Items/itemsIssuesPage";
import AddItemsIssuePage from "./pages/Items-Add/Add-Items-IssuePage";
import ItemsChatsPage from "./pages/Items/itemsChatsPage";
import ItemsRemoteMsgPage from "./pages/Items/itemsRemoteMsgPage";
import TestsPage from "./pages/Tests/TestPage";
import CreateTestPage from "./pages/Tests/CreateTestPage";
import ActivitiesPage from "./pages/Activities/ActivitiesPage";
import MembersPage from "./pages/Members/MembersPage";
import MembersAddPage from "./pages/Members/MembersAddPage";
import AccessDeniedPage from "./pages/Access-Denied/Access-DeniedPage";
import SchPage from "./pages/SchPage/SchPage";
import PslistPage from "./pages/ps-list/PslistPage.jsx";
import CreatePsPage from "./pages/create-ps/CreatePsPage";
import MemberProfilePage from "./pages/Member-profile/MemberProfilePage";
import MemberProfileEditPage from "./pages/Member-ProfileEdit-Page/MemberProfileEditPage";
import ShowTaskPage from "./pages/showTask/ShowTaskPage";
import AddTaskPage from "./pages/Items-Add/Add-Items-TaskPage";
import LinegraphPsPage from "./pages/reports-LineGraph-Ps/LinegraphPsPage";
import ReportCountriesPage from "./pages/report-countries/reportCountriesPage";
import ReportsStatusPage from "./pages/repors-status/ReportsStatusPage";
import VersionReportsPage from "./pages/versionReports/VersionReportsPage";
import PwrReportsPage from "./pages/PwrReports/PwrReportsPage";
import SvrPage from "./pages/svrPage/SvrPage";
import Home from "./pages/home/home";
import ItemsTestPage from "./pages/Items/itemsTestPage";

export default function App() {
  return (
    <div>
      <Toaster />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/send-email" element={<EnterEmailPage />} />
        <Route path="/change-pass" element={<ChangePassPage />} />
        <Route path="/items/:id" element={<ItemsPage />} />
        <Route path="/item-tasks/:id" element={<ItemsTasksPage />} />
        <Route path="/add-item-task/:id" element={<AddTaskPage />} />
        <Route path="/item-issues/:id" element={<ItemsIssuesPage />} />
        <Route path="/item-test/:id" element={<ItemsTestPage />} />
        <Route path="/add-item-issue/:id" element={<AddItemsIssuePage />} />
        <Route path="/item-chats/:id" element={<ItemsChatsPage />} />
        <Route path="/item-remotemsg/:id" element={<ItemsRemoteMsgPage />} />
        <Route path="/tests" element={<TestsPage />} />
        <Route path="/add-test" element={<CreateTestPage />} />
        <Route path="/activity" element={<ActivitiesPage />} />
        <Route path="/members" element={<MembersPage />} />
        <Route path="/add-members" element={<MembersAddPage />} />
        <Route path="/PslistPage" element={<PslistPage />} />
        <Route path="createPsPage" element={<CreatePsPage />} />
        <Route path="/ShowTaskPage" element={<ShowTaskPage />} />
        <Route path="/LinegraphPsPage" element={<LinegraphPsPage />} />
        <Route path="/versionReports" element={<VersionReportsPage />} />
        <Route path="/ReportCountriesPage" element={<ReportCountriesPage />} />
        <Route path="/ReportsStatusPage" element={<ReportsStatusPage />} />
        <Route path="/PwrReports" element={<PwrReportsPage />} />
        <Route path="/svrReports" element={<SvrPage />} />
        <Route path="/access-denied" element={<AccessDeniedPage />} />
        <Route path="/SchPage" element={<SchPage />} />
        <Route path="/MemberProfilePage" element={<MemberProfilePage />} />
        <Route
          path="/MemberProfileEditPage"
          element={<MemberProfileEditPage />}
        />
      </Routes>
    </div>
  );
}
