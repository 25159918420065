import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import reportCss from './ReportsSatrCountries.module.css'
import { Pie } from 'react-chartjs-2'
// import { Link } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { useState } from 'react'
import { useEffect } from 'react'
import { axiosApi } from '../../defz'
import { toast } from 'react-hot-toast';
import Loader from '../Loader/Loader'
import { FaFileDownload } from 'react-icons/fa';

ChartJS.register(ArcElement, Tooltip, Legend)


export default function ReportsSatrCountries() {

    // const [hex, setHex] = useState('#ffffff')
    const [StatusData, setStatusData] = useState({})
    const [percentages, setPersentages] = useState([])
    const [colorArr, setColorArr] = useState([])
    const [Loading, setLoading] = useState(false)
    const [link, setLink] = useState("")

    const state = {
        labels: StatusData?.data,
        datasets: [
            {
                label: 'rainfall',
                backgroundColor: colorArr,
                data: percentages
            }
        ]
    }
    const exportHandeling = () => {
        axiosApi(
            "api/excel",
            {
                type: "contry"
            },
            localStorage.getItem('admin-token')
        )
            .then((res) => {
                toast.success("success")
                console.log(res);
                setLink(res.data.link)
            })
            .catch((res) => {
                toast.error("server Err")
            })
    }

    useEffect(() => {
        setLoading(true)
        let sum = 0
        let arr = []
        let colorArr1 = []
        axiosApi(
            'api/report/finder/contry',
            "",
            localStorage.getItem('admin-token')
        )
            .then(res => {
                console.log(res);
                for (let i = 0; i < res.data.count.length; i++) {
                    sum += res.data.count[i]
                }
                res.data.count.map((item) => {
                    return arr.push((item / sum) * 100)
                })
                setPersentages(arr)
                setStatusData(res.data)

                for (let y = 0; y < res.data.data.length; y++) {
                    let randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
                    // setHex(randomColor)
                    colorArr1.push(randomColor)
                }
                setColorArr(colorArr1)
                setLoading(false)

            })
            .catch((res) => {
                // console.log(res);
                toast.error("Fail to connect to server")
                setLoading(false)
            })
    }, [])

    return (
        <div className={reportCss.container}>
            {Loading && <Loader />}
            <h1>Reports</h1>
            <div className={reportCss.main}>
                <div className={reportCss.stats}>
                    <span className={reportCss.item}>
                        <NavLink to='/LinegraphPsPage'>Stat 1</NavLink>
                    </span>
                    <span className={reportCss.item}>
                        <NavLink to='/ReportCountriesPage'>Stat 2</NavLink>
                    </span>
                    <span className={reportCss.item}>
                        <NavLink to='/ReportsStatusPage'>Stat 3</NavLink>
                    </span>
                    <span className={reportCss.item}>
                        <NavLink to='/versionReports'>Stat 4</NavLink>
                    </span>
                    <span className={reportCss.item}>
                        <NavLink to='/PwrReports'>Stat 5</NavLink>
                    </span>
                    <span className={reportCss.item}>
                        <NavLink to='/svrReports'>Stat 6</NavLink>
                    </span>
                </div>
                <div className={reportCss.chartMain}>
                    <div className={reportCss.chartHead}>
                        <h2>county</h2>
                        <NavLink to={link} className={reportCss.exportIcon} onClick={exportHandeling}>
                            Export
                            <FaFileDownload size="20px" />
                        </NavLink>
                    </div>
                    <div>
                        <Pie
                            className={reportCss.countryChart}
                            data={state}
                            options={{
                                title: {
                                    display: true,
                                    Text: 'wwwww',
                                    fontSize: 20
                                },
                                legend: {
                                    display: true,
                                    position: 'right'
                                }
                            }}
                            width={700}
                            height={700}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
