import React, { useEffect, useState } from "react";
import ItemCSS from "./Item.module.css";
import { axiosApi } from "../../defz";
import toast from "react-hot-toast";
import Loader from "../../components/Loader/Loader";
import { BsFillPersonFill } from "react-icons/bs";
import { RiSendPlaneFill } from "react-icons/ri";
import { useParams } from "react-router-dom";

export default function ItemChats() {
  const GET_CHAT = "api/user/chat/get";

  const { id } = useParams();

  const [load, setLoad] = useState(false);
  const [psData, setPsData] = useState();
  const [msg, setMsg] = useState("");
  const [chatsData, setChatsData] = useState();

  const getPsInfo = () => {
    axiosApi(
      "api/user/get/ps_one",
      { psid: id },
      localStorage.getItem("admin-token")
    )
      .then((res) => {
        console.log(res);
        setPsData(res.data.data[0]);
      })
      .catch((res) => toast.error("Fail to connect to server"));
  };

  useEffect(() => {
    setInterval(() => {
      getPsInfo();
    }, 4000);
    // eslint-disable-next-line
    axiosApi(GET_CHAT, { psid: id }, localStorage.getItem("admin-token")).then(
      (res) => {
        console.log(res.data.data);
        setChatsData(res);
      }
    );
  }, []);

  const sendMsg = () => {
    if (msg.trim()) {
      axiosApi(
        "api/user/chat/send",
        {
          psid: id,
          msg,
        },
        localStorage.getItem("admin-token")
      )
        .then((res) => {
          console.log(res);
          getPsInfo();
          setMsg("");
        })
        .catch((res) => toast.error("Fail to connect to server"));
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      sendMsg();
    }
  };

  return (
    <div className={ItemCSS.container}>
      {load && <Loader />}
      <div className={ItemCSS.items}>
        {/* <aside>
          <div className={ItemCSS.ver}>
            <p>Version:</p>
            <p>{psData?.test_version}</p>
          </div>
          <div className={ItemCSS.U}>
            <p>U:</p>
            <input type="checkbox" />
            <p>1.2.3.4</p>
            <button>Complete</button>
          </div>
          <div className={ItemCSS.cal}>
            <p>Oout:</p>
            <p>{psData?.date_made}</p>
          </div>
          <div className={ItemCSS.noSer}>
            <p>Number of services:</p>
            <p>12</p>
          </div>
          <div className={ItemCSS.ser}>
            {psData?.srv.map((item, index) => {
              return (
                <button key={index} className={ItemCSS.serBtn}>
                  {item.title}
                </button>
              );
            })}
          </div>
          <div className={ItemCSS.autoR}>
            <h4>Automatic report</h4>
            <div>
              <button>Download API</button>
              <button>Deactivate</button>
            </div>
          </div>
          <div className={ItemCSS.manR}>
            <h4>Manual Report</h4>
            <div>
              <button>Down</button>
              <button>Up</button>
            </div>
            <button className={ItemCSS.maintenanceBtn}>Maintenance</button>
          </div>
        </aside> */}
        <section className={ItemCSS.main} style={{ width: "100%" }}>
          <div className={ItemCSS.TasksContainer}>
            {/* <BsFillPersonFill size="30" /> */}
            <div className={ItemCSS.Comments}>
              <div className={ItemCSS.CommentsMSG}>
                {chatsData?.data.data.slice(0).map((item, index) => {
                  return (
                    <>
                      {chatsData.data.user_id === item.sender ? (
                        <div
                          key={index}
                          className={`${ItemCSS.commentsItemsME}`}
                        >
                          <p>{item?.sender_name}</p>
                          <p>{item.msg}</p>
                        </div>
                      ) : (
                        <div
                          key={index}
                          className={`${ItemCSS.commentsItemsOther}`}
                        >
                          <p>{item?.sender_name}</p>
                          <p>{item.msg}</p>
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
              <footer className={ItemCSS.inpContainer}>
                <input
                  type="text"
                  value={msg}
                  placeholder="Type your comment ..."
                  onChange={(e) => setMsg(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <button onClick={sendMsg}>
                  <RiSendPlaneFill size="25" />
                </button>
              </footer>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
