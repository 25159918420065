import React from "react";
import CreatePs from "../../components/createPS/createPS";
import Sidebar from "../../components/Sidebar/Sidebar-menu";
import PagesCSS from "../Styles/pages.module.css";

export default function CreatePsPage() {
  return (
    <div className={PagesCSS.container}>
      <Sidebar page="pslist" />
      <main className={PagesCSS.main_psADD}>
        <CreatePs />
      </main>
    </div>
  );
}
