import React from "react";
import Sidebar from "../../components/Sidebar/Sidebar-menu";
import MemberProfile from "../../components/memberProfile/MemberProfile";
import PagesCSS from "../Styles/pages.module.css";

export default function MemberProfilePage() {
  return (
    <div className={PagesCSS.container}>
      <Sidebar />
      <main className={PagesCSS.main}>
        <MemberProfile />
      </main>
    </div>
  );
}
