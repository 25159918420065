import React, { useEffect, useState } from 'react';
import ActiviesInfoCSS from './Activities-info.module.css';
import { axiosApi } from '../../defz';
import { AiFillCaretDown } from 'react-icons/ai';
import Loader from '../../components/Loader/Loader';
import { Pagination } from '@mui/material';

export default function Activitiesinfo() {
	const [activityData, setActivityData] = useState([]);
	const [load, setLoad] = useState(false);
	const [page, setPage] = useState(1);
	const [maxPage, setMaxPage] = useState();

	useEffect(() => {
		setLoad(true);
		axiosApi(
			'api/user/get/activity',
			{ number: 1 },
			localStorage.getItem('admin-token')
		).then(res => {
			console.log(res);
			setLoad(false);
			setActivityData(res.data.info);
			setMaxPage(res.data.info.max_page);
		});
	}, [page]);

	const handleChange = (e, p) => {
		setPage(p);
	};

	return (
		<div className={ActiviesInfoCSS.container}>
			<div>{load && <Loader />}</div>
			<div className={ActiviesInfoCSS.titleBox}>
				<h1>Activities</h1>
				{/* <div className={ActiviesInfoCSS.addBtn}>
					<AiOutlinePlusCircle />
					<Link to='#'>Add Filters</Link>
				</div> */}
			</div>
			<div className={ActiviesInfoCSS.items}>
				<table>
					<thead>
						<tr>
							<th>
								No
								<AiFillCaretDown />
							</th>
							<th>
								<div className={ActiviesInfoCSS.activity}>
									Activity
									<AiFillCaretDown />
								</div>
							</th>
							<th className={ActiviesInfoCSS.userTitle}>
								User
								<AiFillCaretDown />
							</th>
						</tr>
					</thead>
					<tbody>
						{activityData.map((item, index) => {
							return (
								<tr key={index} className={ActiviesInfoCSS.row}>
									<th className={ActiviesInfoCSS.info}>{index + 1}</th>
									<th className={ActiviesInfoCSS.info}>{item.message}</th>
									<th className={ActiviesInfoCSS?.info}>{item?.user?.user}</th>
								</tr>
							);
						})}
					</tbody>
				</table>
				<footer className={ActiviesInfoCSS.Pagination}>
					<Pagination count={maxPage} color='primary' onChange={handleChange} />
				</footer>
			</div>
		</div>
	);
}
